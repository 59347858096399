import React from 'react';

const ToggleNavBar = ({ openNavBar, closeNavBar }) => (

  <div className="nav-toggle" onClick={() => openNavBar()}><span><i></i></span></div>
)

const GmapsView = ({ lat, lng }) => (
  <div id="map" className="map" data-lat={lat} data-lng={lng} data-title="PBG Desarrollos"></div>
)

const AlertWeb = ({ msj = false }) => (
  <div class="alert-success">
    <p>{msj ? msj : `Gracias por tu consulta, nos contactaremos a la brevedad.`}</p>
  </div>
)

export default ToggleNavBar;
export { GmapsView, AlertWeb };