import AuthService from './AuthService';
import moment from 'moment';
import 'moment/locale/es';

const Auth = new AuthService();

const OliverHelper = {
  showAddressName: function (domicilio) {
    let calle = domicilio.calle;
    let altura = domicilio.altura;
    let piso = domicilio.piso ? domicilio.piso : '';
    let dpto = domicilio.dpto ? domicilio.dpto : '';
    let ciudad = domicilio.ciudad ? domicilio.ciudad : '';
    let barrio = domicilio.barrio;
    return calle + ' ' + altura + ' ' + piso + dpto + ', ' + barrio + ' ' + ciudad;
  },
  loggedIn: function () {
    return Auth.loggedIn();
  },
  logout: function () {
    Auth.logout();
  },
  getProfile: async function () {
    await Auth.getProfile();
  },
  validateEmail: function (email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },
  muestraFecha: function (date, nice = false) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return nice ? [day, month, year].join('/') : [year, month, day].join('-');
  },
  addMinutes: function (date, minutes) {
    return new Date(date.getTime() + minutes * 60000);
  },
  restMinutes: function (date, minutes) {
    return new Date(date.getTime() - minutes * 60000);
  },

  updateDimensions: function (width) {
    if (width > 768) {
      return true
    } else {
      return false
    }
  },


  ClientAPI: {
    sendContact: function (params) { return Auth.post('emailContact', params); },
    askForProject: function (params) { return Auth.post('askForProject', params); },

    getProjects: function (params) { return Auth.get('projects', params); },
    getProject: function (params) { return Auth.get('project', params); },
    login: function (params) { return Auth.post('login', params); },
    forgotAccount: function (params) { return Auth.postPublic('forgotAccount', params); },
    saveProfile: function (params) { return Auth.post('profile', params); },
    changePass: function (params) { return Auth.post('changePass', params); },
    user: function (params) { return Auth.get('user', params); },
    misUnidades: function (params) { return Auth.get('misUnidades', params); },
    showUnidadesDisponibles: function (params) { return Auth.get('showUnidadesDisponibles', params); }
  },

};

//MOTIVOS DLEGACION
const GlobalVars = {
  jsUcfirst: (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
  showDate: (eachProject, dateDirectrly = false, date_format = "MMMM YYYY") => GlobalVars.jsUcfirst(moment(dateDirectrly ? eachProject : eachProject.emprendimiento.fecha_entrega).format(date_format)),
  openSiteContact: () => {
    document.body.className = 'contact-shown';
  },
  LINK_IG: "https://www.instagram.com/pbg_desarrollos/",
  LINK_FB: "https://www.facebook.com/pbgdesarrollos",
  GMAPS_KEY: process.env.GMAPS_KEY, // :D Variable key para el mapa
  BARRIOS: [
    { name: 'palermo', title: 'EXPLORÁ EL BARRIO', image: '/images/project-detail/neighborhood-slider-1-large.jpg', subTitle: 'INFINITAS POSIBILIDADES', description: 'Lorem ipsum dolor sit amet, consectetur adelit. Maecenas tellus nisl, bibendum non cursus cursus, pharetra et elit. Suspendisse eget neque non erat molestie tas. In varius lectus vel nisl posuere mat Donec ut ante dolor. Nam aliquam elit at vestibu hendrerit. Nunc eget euismod tortor, in sagittis sem. Sed aliquam sit amet porta sapien vestibulum vel. Sed tortor euismod, pharetra velit ac, placerat nunc. Nullam mauris ligula, elementum id luctus eu, pulvinar a libero.' }
  ]
}


export default OliverHelper;
export { GlobalVars };