import React from "react";
import decode from 'jwt-decode';
import ToggleNavBar, { AlertWeb } from "../Tools/Utils";
import { withRouter } from "react-router-dom";
import OliverHelper, { GlobalVars } from "../../utils/Oliverhelper";
import swal from 'sweetalert';
import { CeduLogoWhite } from "../logos/cedu";

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth, sent: false,
      profile: {},
      contact: { nombre: "", email: '', sending: false, telefono: '', mensaje: '', logged: false }
    };
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight, scrollY: '' });
  };

  async componentDidMount() {
    //window.addEventListener('resize', this.updateDimensions);
    //window.addEventListener('scroll', this.listenToScroll)

    let token = OliverHelper.loggedIn();
    if (token) {
      let profile = decode(token);

      this.setState({ logged: true, profile: profile });
    }
  }
  componentWillUnmount() {
    //window.removeEventListener('resize', this.updateDimensions);
    //window.removeEventListener('scroll', this.listenToScroll)
  }



  getActualMethod = () => {
    let href = window.location.href;
    let actual = 'home';
    if (href.search('proyectos') !== -1) {
      console.log('aca');
      actual = 'proyectos';
    } else if (href.search('proyecto') !== -1) {
      actual = 'proyecto';
    } else if (href.search('ingresar') !== -1) {
      actual = 'ingresar';
    } else if (href.search('mis-unidades') !== -1) {
      actual = 'mis-unidades';
    } else if (href.search('mi-unidad/') !== -1) {
      actual = 'mi-unidad';
    } else if (href.search('mi-cuenta') !== -1) {
      actual = 'mi-cuenta';
    } else if (href.search('nueva-inversion') !== -1) {
      actual = 'nueva-inversion';
    } else if (href.search('terrenos') !== -1) {
      actual = 'terrenos';
    }
    return actual;
  }
  listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight

    const scrolled = (winScroll / height) * 100

    this.setState({
      scrollY: scrolled,
    })
    //console.log('el scroll es: ',this.state.scrollY)
  }


  openNavBar = () => {
    window.toggleNav();
  }
  openSiteContact = () => document.body.className = 'contact-shown';

  scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });


  closeNavBar = () => document.body.className = '';

  setFormInput = (name, val) => {
    let contact = this.state.contact;
    contact[name] = val;
    this.setState({ contact });
  }

  sendContact = () => {
    if (!this.state.contact.nombre.length || !this.state.contact.email.length || !this.state.contact.telefono.length || !this.state.contact.mensaje) {
      swal("Atención!", 'Debe completar todos los campos del formulario', "warning");
      return false;
    }
    if (!OliverHelper.validateEmail(this.state.contact.email)) {
      swal("Atención!", 'Debe completar con un email válido', "warning");
      return false;
    }

    this.setState({ sending: true });
    OliverHelper.ClientAPI.sendContact(this.state.contact).then(response => {
      console.log('llega', response);
      this.setState({ sent: true, sending: false })
    })

  }

  logout = () => {
    OliverHelper.logout();
    this.setState({ logged: false })
    window.location.href = '/';
  }

  render() {
    let method = this.getActualMethod();
    console.log('method', method, 'profile', this.state.profile);
    //console.log('re render Home.js => el ancho de la pantalla es de: ', this.state.width);
    return (
      <main>
        <HeaderNav openNavBar={this.openNavBar} close={this.closeNavBar} />
        <SiteNav profile={this.state.profile} logout={this.logout} method={method} logeado={this.state.logged} openSiteContact={this.openSiteContact} openNavBar={this.openNavBar} />
        <SiteContact sending={this.state.sending} sent={this.state.sent} sendContact={this.sendContact} contact={this.state.contact} setState={this.setFormInput} />

        {/** ACA IMPORTA EL COMPONENTE DE CADA VISTA */}
        {this.props.children}

        <Footer sending={this.state.sending} sent={this.state.sent} openSiteContact={this.openSiteContact} openNavBar={this.openNavBar} sendContact={this.sendContact} contact={this.state.contact} setState={this.setFormInput} />
        <a href="#" className="button contact-open is-icon hide-for-large" onClick={() => this.openNavBar()} >Hacenos tu consulta</a>
        <span className="btn-top show-for-large" onClick={() => this.scrollToTop()}>Volver a subir</span>
        <div id="is-large-up" className="show-for-large"></div>

      </main>
    );
  }
}

export default withRouter(Layout);

const Footer = ({ sendContact, contact, setState, openNavBar, openSiteContact, sent, sending }) => (
  <footer className="site-footer">
    <div className="row">
      <div className="grid-x grid-padding-x">
        <div className="small-12 large-6 large-offset-1 cell">
          <h3 className="title invert is-underline">Contactate <br className="hide-for-large" />con nosotros</h3>
          <div className="form-wrapper">
            <form className="invert" action="#" method="GET">
              <div className="input-wrapper">
                <label htmlFor="footer-contact-name" className="hide">Nombre</label>
                <input type="text" id="footer-contact-name"
                  value={contact.nombre}
                  onChange={(data) => setState('nombre', data.target.value)}
                  name="nombre" placeholder="Nombre_" required />
              </div>
              <div className="input-wrapper">
                <label htmlFor="footer-contact-email" className="hide">Email</label>
                <input
                  type="text"
                  id="footer-contact-email"
                  value={contact.email}
                  onChange={(data) => setState('email', data.target.value)}
                  name="email" placeholder="Email_" required />
              </div>
              <div className="input-wrapper">
                <label htmlFor="footer-contact-phone" className="hide">Teléfono</label>
                <input id="footer-contact-phone"
                  value={contact.telefono}
                  type='text'
                  onChange={(data) => setState('telefono', data.target.value)}
                  name="telefono" placeholder="Tel_" required />
              </div>
              <div className="input-wrapper type-textarea">
                <label htmlFor="footer-contact-message" className="hide">Mensaje</label>
                <textarea id="footer-contact-message"
                  onChange={(data) => setState('mensaje', data.target.value)}
                  name="mensaje" placeholder="Mensaje_" cols="40" rows="3">
                  {contact.mensaje}
                </textarea>
              </div>
              {sent ?
                <AlertWeb />
                : <div className="input-wrapper type-submit text-center">
                  <input type="button" value={sending !== true ? "Enviar" : "Enviando..."} onClick={() => sendContact()} className="button" />
                </div>
              }
            </form>
          </div>
          <p className="copyright show-for-large">© Copyright PBG - by
                  <a href="https://somosbrava.com/" target="_blank">Brava Estudio</a>
          </p>
        </div>

        <div className="small-12 large-5 cell">
          <div style={{ display: 'flex', alignItems: 'center'}}>
            <div >
              <h4 className="title" style={{ margin: 'auto'}}>Mundo PBG</h4>    
              <div className="social" style={{ margin: 'auto', display: 'flex', marginTop: '30px'}}>
                <a href={GlobalVars.LINK_FB} ><img src="/images/icon-facebook-white.png" /> Facebook</a>
                <a href={GlobalVars.LINK_IG}><img src="/images/icon-instagram-white.png" /> Instagram</a>
              </div>
            </div>
            <div>
              <div style={{marginLeft: '200px'}}>
                <div>Miembro</div>
                <CeduLogoWhite  />
              </div>
            </div>
          </div>
          <div>
            <div>
              <div style={{ width: '200px', marginTop: '80px', marginBottom: '30px' }}>
                <img src="/images/imagotipo-white.png" className="logo" alt="Logo PBG" /><br />
              </div>
              <div className="contact-info">
                <a href="tel:+54 11 5353 9901">T. (5411) 5353 9901</a>
                <address>Av. Libertador 5936 12°B <br />Ciudad de Buenos Aires, Argentina</address>
                <a href="mailto:contacto@pbgdesarrollos.com.ar">contacto@pbgdesarrollos.com.ar</a><br />
              </div>
            </div>
            <div className="sitemap">
              <h4 className="title">Sitemap</h4>
              <a href="/">Home</a><br />
              <a href="/proyectos-construccion">Proyectos</a><br />
              <a href="/vende-tu-terreno">Terrenos</a><br />
              <a href="#" onClick={() => GlobalVars.openSiteContact()} >Contacto</a><br />

            </div>
          </div>
        </div>

        {/* <div className="small-12 large-5 cell">
          <span className="btn-top-footer hide-for-large">Top</span>
          <div className="contact-info">
            <br />
            <br />
            <br />
          </div>
          <div className="sitemap">
          </div>
          <p className="copyright hide-for-large">© Copyright PBG - by
                  <a href="https://somosbrava.com/" target="_blank">Brava Estudio</a></p>
        </div> */}
      </div>
    </div>
  </footer>
)

class SiteContact extends React.Component {
  constructor(props) {
    super(props);
    this.state = { contact: { nombre: "", email: '', telefono: '', mensaje: '' } };
  }
  validateData = () => {
    console.log('this.state', this.state);
  }
  changeState = (name, val) => {
    let contact = this.state.contact;
    contact[name] = val;
    this.setState({ contact });
  }



  render() {

    return (
      <div className="site-contact" >
        <img src="/images/imagotipo.png" className="logo hide-for-large" />
        <div className="form-wrapper">
          <form action="json/contact.json" method="GET">
            <h4 className="title is-underline">Hacenos tu consulta</h4>
            <div className="input-wrapper">
              <label htmlFor="header-contact-name" className="hide">Nombre</label>
              <input type="text" id="header-contact-name"
                onChange={(data) => this.props.setState('nombre', data.target.value)}
                value={this.props.contact.nombre} name="nombre" placeholder="Nombre_"
                required />
            </div>
            <div className="input-wrapper">
              <label htmlFor="header-contact-email" className="hide">Emaill</label>
              <input
                type="text"
                id="header-contact-email"
                onChange={(data) => this.props.setState('email', data.target.value)}
                value={this.props.contact.email} name="email" placeholder="Email_" required />
            </div>
            <div className="input-wrapper">
              <label htmlFor="header-contact-phone" className="hide">Teléfono</label>
              <input type="text" id="header-contact-phone"
                onChange={(data) => this.props.setState('telefono', data.target.value)}
                value={this.props.contact.telefono} name="telefono" placeholder="Tel_" required />
            </div>
            <div className="input-wrapper type-textarea">
              <label htmlFor="header-contact-message" className="hide">Mensaje</label>
              <textarea id="header-contact-message"
                onChange={(data) => this.props.setState('mensaje', data.target.value)}
                name="mensaje" placeholder="Mensaje_" cols="40" rows="3">{this.props.contact.mensaje}</textarea>
            </div>

            <div className="input-wrapper type-submit">
              {this.props.sent ?
                <AlertWeb /> :
                <input type="button"
                  onClick={() => { console.log('aca'); this.props.sendContact() }}
                  value={this.props.sending !== true ? "Enviar" : "Enviando..."} className="button" />
              }
            </div>
          </form>
        </div>
        <div className="contact-info">
          <div className="social">

            <a href={GlobalVars.LINK_IG} target="_blank"><img src="/images/icon-instagram.png" /></a>
            <a href={GlobalVars.LINK_FB} target="_blank"><img src="/images/icon-facebook.png" /></a>
          </div>
          <a href="tel:+54 11 5353 9901">T. (5411) 5353 9901</a>
          <address>Av. Libertador 5936 12°B <br />Ciudad de Buenos Aires, Argentina</address>
          <a href="mailto:contacto@pbgdesarrollos.com.ar">contacto@pbgdesarrollos.com.ar</a>
        </div>
      </div>
    )
  }
}

const SiteNav = ({ openNavBar, profile, openSiteContact, logout, logeado, method }) => {
  console.log('eval', method.includes('mi-unidad'))
  let showMenuLogged = method == 'mi-cuenta' || method == 'mis-unidades' || method == 'nueva-inversion' || method == 'mi-unidad' || method.includes('mi-unidad') ? true : false;


  return (
    logeado && showMenuLogged ?
      <div className="site-nav">
        <img src="/images/imagotipo.png" className="logo" />
        <p className="welcome">Hola {profile.nombre}!</p>
        <nav>
          <a href="/mi-cuenta" className="active">Mi Cuenta</a><br />
          <a href="/mis-unidades">Mis Unidades</a><br />
          <a href="/nueva-inversion">Suma una nueva inversión</a><br />
        </nav>
        <a href="#" onClick={() => logout()} className="link-logout">Cerrar sesión</a>
        <div className="contact-info">
          <div className="social">
            <a href={GlobalVars.LINK_IG} target="_blank"><img src="/images/icon-instagram.png" /></a>
            <a href={GlobalVars.LINK_FB} target="_blank"><img src="/images/icon-facebook.png" /></a>
          </div>
          <a href="tel:+54 11 5353 9901">T. (5411) 5353 9901</a>
          <address>Av. Libertador 5936 12°B <br />Ciudad de Buenos Aires, Argentina</address>
          <a href="mailto:contacto@pbgdesarrollos.com.ar">contacto@pbgdesarrollos.com.ar</a>
        </div>
      </div>
      :
      <div className="site-nav">
        <img src="/images/imagotipo.png" className="logo" />
        <nav>
          <a href="/" className={method == 'home' ? "active" : ''}>Home</a><br />
          <a href="/proyectos-construccion" className={method == 'proyectos' ? "active" : ''}> Proyectos</a><br />
          <a href="/vende-tu-terreno" className={method == 'terrenos' ? "active" : ''}>Terrenos</a><br />

          <a href="#" onClick={() => GlobalVars.openSiteContact()}>Contacto</a><br />
          <a href="/mis-unidades-pbg" className={` ${method == 'ingresar' ? "active" : ''}`}> Mis Unidades</a>
          <hr />
        </nav>
        {logeado ? <a href="#" onClick={() => logout()} className="link-logout">Cerrar sesión</a> : null}
        <div className="contact-info">
          <div className="social">
            <a href={GlobalVars.LINK_IG} target="_blank"><img src="/images/icon-instagram.png" /></a>
            <a href={GlobalVars.LINK_FB} target="_blank"><img src="/images/icon-facebook.png" /></a>
          </div>
          <a href="tel:+54 11 5353 9901">T. (5411) 5353 9901</a>
          <address>Av. Libertador 5936 12°B <br />Ciudad de Buenos Aires, Argentina</address>
          <a href="mailto:contacto@pbgdesarrollos.com.ar">contacto@pbgdesarrollos.com.ar</a>
        </div>
      </div>
  )
}
const HeaderNav = ({ openNavBar, close }) => (
  <header className="site-header">
    <div className="top">
      <a href="/" className="logo"><img src="/images/isotipo.png" /></a>
      <ToggleNavBar openNavBar={openNavBar} closeNavBar={close} />
      <span className="button contact-open show-for-large" onClick={() => GlobalVars.openSiteContact()}  >Hacenos tu consulta</span>
    </div>
  </header>
)