import React from 'react';
import { withRouter } from "react-router-dom";
import { InvestInPbg, PbgLocation, GalleryPreFooter } from './Home';
import OliverHelper, { GlobalVars } from '../../utils/Oliverhelper';
import moment from 'moment';
import { AlertWeb } from '../Tools/Utils';
import swal from 'sweetalert';


class Terrenos extends React.Component {
  constructor(props) {
    super(props);
    this.state = { projects: [], contact: { nombre: "", email: '', telefono: '', mensaje: '' }, bannersFooter: [] };
  }

  componentDidMount() {
    document.title = 'Vende tu terreno o casa';
    OliverHelper.ClientAPI.getProjects().then(response => {
      this.setState({ projects: response.emprendimientos });
    });
    OliverHelper.ClientAPI.getProjects({ home_top_slider: 1 }).then(response => {
      this.setState({ bannersFooter: response.bannersFooter });
    });
  }


  setFormInput = (name, val) => {
    let contact = this.state.contact;
    contact[name] = val;
    this.setState({ contact });
  }

  sendContact = () => {
    if (!this.state.contact.nombre.length || !this.state.contact.email.length || !this.state.contact.telefono.length || !this.state.contact.mensaje) {
      swal("Atención!", 'Debe completar todos los campos del formulario', "warning");
      return false;
    }
    if (!OliverHelper.validateEmail(this.state.contact.email)) {
      swal("Atención!", 'Debe completar con un email válido', "warning");
      return false;
    }
    this.setState({ sending: true });
    OliverHelper.ClientAPI.sendContact(this.state.contact).then(response => {
      console.log('llega', response);
      this.setState({ sent: true, sending: false });
    })

  }

  goTo = (project) => this.props.history.push('/proyecto/' + project.emprendimiento.id);

  render() {
    return (
      <div>
        <div className="top-content is-single">
          <div className="image cover"
            style={{ background: `url(images/land/main-photo.jpg) no-repeat` }} //<--------- HARCODEADO
            data-interchange="[images/land/main-photo.jpg, portrait], [images/land/main-photo-landscape.jpg, landscape]"></div>
          <div className="text">
            <h2 className="title">Transformá tu propiedad <br className="show-for-medium" />en un proyecto PBG</h2>
            <p>Te ofrecemos distintas opciones para la compra de tu casa, <br className="show-for-medium" />haciéndote partícipe de los enormes beneficios del negocio inmobiliario.</p>
            <a href="#" className="button" data-scroll-to=".section-owner">Contactanos</a>
          </div>
          <div className="social show-for-large">
            <a href={GlobalVars.LINK_IG} target="_blank"><img src="images/icon-instagram-white.png" /></a>
            <a href={GlobalVars.LINK_FB} target="_blank"><img src="images/icon-facebook-white.png" /></a>
          </div>
        </div>
        <section className="site-section section-deal secondary">
          <div className="row">
            <h1 className="title section-title invert is-underline">Un negocio seguro y <br className="hide-for-large" />garantizado <br className="show-for-large" />en dólares</h1>

            <div className="list">
              <div className="grid-x">

                <div className="small-12 medium-4 cell item">
                  <h2>01</h2>
                  <p>Ofrecemos garantías reales para que tu aporte esté completamente asegurado.</p>
                </div>

                <div className="small-12 medium-4 cell item">
                  <h2>02</h2>
                  <p>Vas a tener una cantidad de m2 a estrenar por un valor mayor al de tu propiedad.</p>
                </div>

                <div className="small-12 medium-4 cell item">
                  <h2>03</h2>
                  <p>Tus metros cuadrados pueden también estar en otro de nuestros proyectos.</p>
                </div>

                <div className="small-12 medium-4 cell item">
                  <h2>04</h2>
                  <p>Podés elegir unidades modernas y a estrenar, sin gastos, listas para vender o alquilar.</p>
                </div>

                <div className="small-12 medium-4 cell item">
                  <h2>05</h2>
                  <p>Trabajamos con los mejores estudios de arquitectos para garantizar la calidad de nuestras obras.</p>
                </div>

                <div className="small-12 medium-4 cell item">
                  <h2>06</h2>
                  <p>Tenés la posibilidad de hacer una combinación de unidades y dinero en efectivo.</p>
                </div>

                <div className="small-12 medium-4 cell item">
                  <h2>07</h2>
                  <p>Contás con asesoramiento legal, notarial y contable.</p>
                </div>

                <div className="small-12 medium-4 cell item">
                  <h2>08</h2>
                  <p>Podés tener unidades en la misma ubicación de tu propiedad original.</p>
                </div>

                <div className="small-12 medium-4 cell item">
                  <h2>09</h2>
                  <p>Poseemos la estructura comercial para poder vender tus metros rapidamente.</p>
                </div>

              </div>
            </div>
            <div className="text-center">
              <a href="#" className="button appear-animation fadeIn" data-animation="fadeInUp" data-scroll-to=".section-owner">Escribinos</a>
            </div>
          </div>
        </section>
        <section className="site-section section-benefits">
          <div className="row">
            <h1 className="title section-title">Por todo esto <br className="hide-for-large" />obtenés</h1>

            <div className="list">
              <div className="grid-x">

                <div className="small-12 medium-4 cell item appear-animation animated fadeInUp" data-animation="fadeInUp" data-animation-delay="100">
                  <div className="image">
                    <div className="overlay"><img src="images/land/icon-benefits-money.png" className="icon" /></div>
                    <img src="images/land/benefits-1.jpg" />
                  </div>
                  <div className="text">
                    <p>Rentabilidad en <br />dólares Para resguardo <br />de tu capital</p>
                  </div>
                </div>

                <div className="small-12 medium-4 cell item appear-animation animated fadeInUp" data-animation="fadeInUp" data-animation-delay="300">
                  <div className="image">
                    <div className="overlay"><img src="images/land/icon-benefits-marker.png" className="icon" /></div>
                    <img src="images/land/benefits-2.jpg" />
                  </div>
                  <div className="text">
                    <p>Unidades a estrenar <br />en tu lote o en otros <br />desarrollos PBG</p>
                  </div>
                </div>

                <div className="small-12 medium-4 cell item appear-animation animated fadeInUp" data-animation="fadeInUp" data-animation-delay="500">
                  <div className="image">
                    <div className="overlay"><img src="images/land/icon-benefits-star.png" className="icon" /></div>
                    <img src="images/land/benefits-3.jpg" />
                  </div>
                  <div className="text">
                    <p>Seguridad juridica <br />con los instrumentos <br />mas seguros</p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>
        <section className="site-section light section-owner">
          <div className="row">
            <div className="grid-x grid-padding-x align-center">
              <div className="small-11 medium-8 large-5 cell text-center">
                <h1 className="title section-title">¿Sos propietario?</h1>
                <div className="section-description">
                  <p>Si tenés un terreno o casa y querés invertir en proyectos inmobiliarios seguros y de alta rentabilidad, dejanos tus datos y un representante de PBG se pondrá en contacto:</p>
                </div>
              </div>
            </div>
          </div>
          <div id="form-owner" className="form-wrapper">
            <div className="bg cover"
              style={{ background: 'url(/images/land/background-form.jpg) no-repeat', backgroundSize: 'cover' }}
              data-interchange="[images/land/background-form.jpg, small], [images/land/background-form-large.jpg, large]">
            </div>
            <div className="row fadeIn appear-animation animated fadeInUp" data-animation="fadeInUp">
              <form action="json/owner.json" method="GET">
                <div className="inner">
                  <span className="title">Datos Personales</span>
                  <div className="input-wrapper">
                    <label for="owner-name" className="hide">Nombre y Apellido</label>
                    <input type="text" id="owner-name"
                      onChange={(data) => this.setFormInput('nombre', data.target.value)}
                      value={this.state.contact.nombre} name="nombre"
                      placeholder="Nombre y Apellido" required />
                  </div>
                  <div className="input-wrapper">
                    <label for="owner-phone" className="hide">Teléfono</label>
                    <input type="text" id="owner-phone"
                      onChange={(data) => this.setFormInput('telefono', data.target.value)}
                      value={this.state.contact.telefono} name="telefono"
                      placeholder="Teléfono" required />
                  </div>
                  <div className="input-wrapper">
                    <label for="owner-email" className="hide">Email</label>
                    <input type="text" id="owner-email"
                      onChange={(data) => this.setFormInput('email', data.target.value)}
                      value={this.state.contact.email} name="email"
                      placeholder="Email" required />
                  </div>
                  <div className="input-wrapper type-textarea">
                    <label className="title" for="owner-property-info">Datos de la propiedad<span>Dirección y cualquier información relevante</span></label>
                    <textarea id="owner-property-info"
                      onChange={(data) => this.setFormInput('mensaje', data.target.value)}
                      name="propiedad" cols="40" rows="4">{this.state.contact.mensaje}</textarea>
                  </div>
                  <div className="input-wrapper type-submit">
                    {this.state.sent ?
                      <AlertWeb /> :
                      <input type="button" onClick={() => this.sendContact()} value={this.state.sending !== true ? "Enviar" : "Enviando..."} className="button" />
                    }
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>

        <GalleryPreFooter data={this.state.bannersFooter} />
      </div>
    );
  }
}


export default withRouter(Terrenos);
