import React from 'react';
import { withRouter } from "react-router-dom";
import '../Styling/accountStyles.css';
import OliverHelper, { GlobalVars } from '../../utils/Oliverhelper';
import moment from 'moment';
import 'moment/locale/es';
import AuthService from '../../utils/AuthService';
import bgMisUnidades from '../Styling/images/home/bg-background-mis-unidades.jpg'
const auth = new AuthService();


class MisUnidades extends React.Component {
  constructor(props) {
    super(props);
    this.state = { dataProject: [], projects: [], loading: true };
  }

  componentDidMount() {
    let logged = auth.loggedIn();
    if (!logged || logged == 'undefined') {
      this.props.history.push('/mis-unidades-pbg');
      return;
    }


    let id = this.props.match.params.id;
    OliverHelper.ClientAPI.user().then((response) => {
      document.title = 'Mis Unidades';
      OliverHelper.ClientAPI.misUnidades({ id: response.client.id }).then(response => {   // <---- HARDCODEADO el ID
        this.setState({ loading: false, dataProject: response.misUnidades });
      });

    })

  }

  getDigitalesByType = (unidad) => {
    var brochureToLink = this.getType('Brochure', unidad.digitales);
    console.log('Brochure', brochureToLink);
    return (
      brochureToLink.image_path != undefined ? <a className="" onClick={() => window.open(brochureToLink.image_path)}>Descargar Brochure</a> : null
    )
  }

  getType = (typeToSearch, digitales) => {
    console.log('digitales', digitales)
    var digitalWanted = {};
    digitales.map(eachDigital => {
      console.log('PARA MAPEAR', eachDigital.tipo, typeToSearch)
      if (eachDigital.tipo === typeToSearch) {
        digitalWanted = eachDigital
      }
    });
    return digitalWanted;
  }

  //goTo = (project) => this.props.history.push('/proyecto/' + project.emprendimiento.id);


  getImageByType = (type, digitales) => {
    let digital = false;
    digitales.map(eachDigital => {
      //console.log(eachDigital.tipo.toLowerCase(), '  con ', type.toLowerCase());
      if (eachDigital.tipo.toLowerCase() == type.toLowerCase()) {
        digital = eachDigital;
        return;
      }
    });
    return digital;
  }


  render() {
    return (
      <div>
        <section className="interface-section first full-height dark">
          <div className="inner">
            <header className="title-wrapper">
              <h1 className="title">Mis Unidades</h1>
              <span className="icon icon-hand-holding-key"></span>
            </header>
            {!this.state.loading ?

              this.state.dataProject.length == 0 ?
                <div style={{ display: 'inline-block', textAlign: 'center', width: '100%' }}>
                  <p style={{ color: '#b3b1b1' }}>
                    Todavía no tenes unidades. ¿Que esperás?.
                </p>
                  <a href={`nueva-inversion`} className="button">Sumá una nueva inversión</a>
                </div>
                :
                <div className="units">

                  {this.state.dataProject.map(eachUnidad => <EachUnidad getImageByType={this.getImageByType} goTo={this.goTo}
                    unidad={eachUnidad}
                    getDigitalesByType={this.getDigitalesByType} />
                  )}
                </div>

              : null}
          </div>
        </section>
        <section className="interface-section primary section-maximize">
          <div className="inner"

            style={{ backgroundImage: `url(${bgMisUnidades})` }}
            data-events="resize">
            <div className="row">
              <div className="grid-x grid-padding-x align-center">
                <div className="small-12 large-8 cell">
                  <h1 className="title section-title invert is-underline">Suma una <br class="hide-for-large" />nueva inversión</h1>
                  <div className="section-description">
                    <p>Si estás buscando potenciar tus ingresos y querés hacer una inversión con tu propiedad o lote hablá con nosotros. En PBG estamos constantemente buscando la materia prima para nuestros próximos proyectos.</p>
                    <a href="/nueva-inversion" className="button" data-animation="fadeInUp">Ver +</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const EachUnidad = ({ unidad, goTo, getDigitalesByType, getImageByType }) => {
  let backImage = getImageByType('mi_unidad', unidad.emprendimiento.digitales);
  return (
    <div className="item">

      <img style={{ cursor: 'pointer' }}
        onClick={() => window.location.href = `mi-unidad/${unidad.emprendimiento.nombre.replace(' ', '-')}/${unidad.emprendimientos_id}`}
        src={backImage.image_path} />

      <div className="content">
        <p className="date-location">{GlobalVars.showDate(unidad)} / {unidad.emprendimiento.domicilio.barrio}</p>
        <h2>{unidad.emprendimiento.nombre}</h2>
        <a href={`mi-unidad/${unidad.emprendimiento.nombre.replace(' ', '-')}/${unidad.emprendimientos_id}`} className="button">Ver mis Unidades</a>
        {getDigitalesByType(unidad.emprendimiento)}
      </div>
    </div>
  )
}

export default withRouter(MisUnidades);
