import React from "react";
import Layout from "../Layout/Layout";
import Home from "../Home/Home";
import Proyectos from "../Home/Proyectos";
import Proyecto from "../Home/Proyecto";
import Login from "../Login/Login";
import MiCuenta from "../Home/MiCuenta";
import MisUnidades from "../Home/MisUnidades";
import MiCuentaNuevasInversiones from "../Home/MiCuentaNuevasInversiones";
import MiUnidad from "../Home/MiUnidad";
import Terrenos from "../Home/Terrenos";


export default {

  publicRoutes: [
    {
      path: "/",
      exact: true,
      nav: true,
      label: 'Inicio',
      main: () => <Layout children={<Home />} />
    },
    {
      path: "/proyectos",
      exact: true,
      nav: true,
      label: 'Proyectos',
      main: () => <Layout children={<Proyectos />} />
    },
    {
      path: "/proyectos-construccion",
      exact: true,
      nav: true,
      label: 'Proyectos',
      main: () => <Layout children={<Proyectos />} />
    },
    {
      path: "/proyecto/:id",
      exact: true,
      nav: true,
      label: 'Proyecto',
      main: () => <Layout children={<Proyecto />} />
    },
    {
      path: "/proyecto/:any/:id",
      exact: true,
      nav: true,
      label: 'Proyecto',
      main: () => <Layout children={<Proyecto />} />
    },
    {
      path: "/ingresar",
      exact: true,
      nav: true,
      label: 'Ingresa',
      main: () => <Layout children={<Login />} />
    },
    {
      path: "/mis-unidades-pbg",
      exact: true,
      nav: true,
      label: 'Ingresa',
      main: () => <Layout children={<Login />} />
    },
    {
      path: "/cambiar-clave",
      exact: true,
      nav: true,
      label: 'Ingresa',
      main: () => <Layout children={<Login changePass={true} />} />
    },
    {
      path: "/mi-cuenta",
      exact: true,
      nav: true,
      label: 'MiCuenta',
      main: () => <Layout children={<MiCuenta />} />
    },
    {
      path: "/mis-unidades",
      exact: true,
      nav: true,
      label: 'MisUnidades',
      main: () => <Layout children={<MisUnidades />} />
    },
    {
      path: "/nueva-inversion",
      exact: true,
      nav: true,
      label: 'NuevaInversion',
      main: () => <Layout children={<MiCuentaNuevasInversiones />} />
    },
    {
      path: "/unidad-detalle/:id",
      exact: true,
      nav: true,
      label: 'UnidadDetalle',
      main: () => <Layout children={<MiUnidad />} />
    },
    {
      path: "/mi-unidad/:any/:id",
      exact: true,
      nav: true,
      label: 'UnidadDetalle',
      main: () => <Layout children={<MiUnidad />} />
    },
    {
      path: "/terrenos",
      exact: true,
      nav: true,
      label: 'Terrenos',
      main: () => <Layout children={<Terrenos />} />
    },
    {
      path: "/vende-tu-terreno",
      exact: true,
      nav: true,
      label: 'Terrenos',
      main: () => <Layout children={<Terrenos />} />
    },

  ],



  routesLogged: [
    {
      path: "/mi-cuenta",
      exact: true,
      nav: true,
      label: 'MiCuenta',
      main: () => <Layout children={<MiCuenta />} />
    },
    {
      path: "/mis-unidades",
      exact: true,
      nav: true,
      label: 'MisUnidades',
      main: () => <Layout children={<MisUnidades />} />
    },
    {
      path: "/nueva-inversion",
      exact: true,
      nav: true,
      label: 'NuevaInversion',
      main: () => <Layout children={<MiCuentaNuevasInversiones />} />
    },
    {
      path: "/unidad-detalle/:id",
      exact: true,
      nav: true,
      label: 'UnidadDetalle',
      main: () => <Layout children={<MiUnidad />} />
    },

  ],

};

