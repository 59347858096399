import React from 'react';
import { withRouter } from "react-router-dom";
import '../Styling/accountStyles.css';
import OliverHelper from '../../utils/Oliverhelper';
import AuthService from '../../utils/AuthService';
import { AlertWeb } from '../Tools/Utils';
import swal from 'sweetalert';

import terraza from '../Styling/images/terraza.png';

const auth = new AuthService();

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visibleChange: false,
            token: {}
        };
    }

    componentDidMount() {
        document.title = 'Ingresa a MIS UNIDADES';
        let logged = auth.loggedIn();
        console.log('logged', logged);
        if (logged && logged != 'undefined') {
            this.props.history.push('/mis-unidades');
        }
    }

    toggleChangePassword = () => {
        this.setState({ visibleChange: !this.state.visibleChange })
    }

    login = () => {


        if (!this.state.user || !this.state.password || this.state.user.length == 0 || this.state.password.length == 0) {
            swal('Atención!', 'Debe completar usuario y contraseña para ingresar', 'warning');
            return;
        }

        OliverHelper.ClientAPI.login({ email: this.state.user, password: this.state.password }).then((response) => {
            console.log('response login', response);
            if (response.token != undefined) {
                auth.setToken(response.token);
                this.setState({ token: response });

                let hasEmpId = this.props.location.search && this.props.location.search.split('emp_id=').length ? this.props.location.search.split('emp_id=') : null;
                let empName = this.props.location.search && this.props.location.search.split('name=').length ? this.props.location.search.split('name=')[1].split('&')[0] : null;

                if (hasEmpId && hasEmpId[1] != undefined) {
                    this.props.history.push('/mi-unidad/' + empName + '/' + hasEmpId[1]);
                } else {
                    this.props.history.push('/mis-unidades');
                }

            } else {
                swal("Atención!", response.error, "warning");
            }
        })
    }
    forgotPass = () => {
        if (!OliverHelper.validateEmail(this.state.email)) {
            swal("Atención!", 'Debe completar con un email válido', "warning");
            return false;
        }
        this.setState({ sendingForgot: true })
        OliverHelper.ClientAPI.forgotAccount({ email: this.state.email }).then((response) => {
            console.log('response forgot', response);
            this.setState({ showEmailForgotSent: true })
        })
    }


    changePass = () => {
        if (this.state.newPassword != this.state.actualPassword) {
            swal("Atención!", 'Las contraseñas no coinciden', "warning");
            return;
        }
        let email = this.props.location.search.split('email=')[1];
        console.log('this props', this.props);
        OliverHelper.ClientAPI.changePass({ token: 1, email: email, password: this.state.newPassword, actualPassword: this.state.actualPassword }).then(response => {
            swal("Perfecto!", response.text, "success");
            window.location.href = '/mis-unidades-pbg';
        })
    }

    render() {


        return (
            <div>
                <section className="login cover"
                    style={{ background: `url(${terraza})`, backgroundSize: 'cover' }}
                    data-interchange="`[${terraza}, portrait], [${terraza}, landscape]`"
                    >
                    <div className="inner">
                        <h1 className="title">Mis metros cuadrados</h1>
                        <form className="login-form" action="json/login.json" method="GET">
                            <div className="input-wrapper">
                                <label htmlFor="login-user" className="hide">Usuario</label>
                                <input type="text" id="login-user" value={this.state.user}
                                    onChange={(e) => this.setState({ user: e.target.value })}
                                    name="usuario" placeholder="Usuario" required />
                            </div>
                            <div className="input-wrapper">
                                <label htmlFor="login-password" className="hide">Contraseña</label>
                                <input type="password" id="login-password"
                                    onChange={(e) => this.setState({ password: e.target.value })}
                                    value={this.state.password} name="Contraseña" placeholder="Contraseña" required />
                            </div>
                            <div className="input-wrapper type-submit text-center">
                                <input type="button" onClick={() => this.login()} value="Entrar" className="button invert" />
                            </div>
                        </form>
                        <span className="open-password-edit" onClick={() => this.toggleChangePassword()} data-change-password>Olvidé mi contraseña</span>
                    </div>
                </section>
                <section className={`password-change ${this.state.visibleChange ? 'visible' : ''}`}>
                    <div className="inner">
                        <h1 className="title">Datos de acceso</h1>
                        <form className="login-form" action="json/password-change.json" method="GET">
                            <div className="input-wrapper">
                                <label htmlFor="password-change-current" className="hide">Email Actual</label>
                                <input type="text" id="password-change-current"
                                    onChange={(e) => this.setState({ email: e.target.value })}
                                    name="actual" placeholder="Email Actual" required />
                            </div>
                            {this.state.showEmailForgotSent ?
                                <AlertWeb msj="Te enviamos un correo para activar tu cuenta" /> :
                                <div className="input-wrapper type-submit text-center">
                                    <input type="button" value={this.state.sendingForgot != true ? "Recuperar cuenta" : 'Enviando...'} onClick={() => this.forgotPass()} className="button invert" />
                                </div>
                            }
                        </form>
                        <div onClick={() => this.toggleChangePassword()} className="close"><span></span></div>
                    </div>
                </section>

                <section className={`password-change ${this.props.changePass ? 'visible' : ''}`}>
                    <div className="inner">
                        <h1 className="title">Datos de acceso</h1>
                        <form className="login-form" action="json/password-change.json" method="GET">
                            <div className="input-wrapper">
                                <label htmlForm="password-change-current" className="hide">Contraseña nueva</label>
                                <input type="password" id="password-change-current"
                                    onChange={e => this.setState({ actualPassword: e.target.value })}
                                    name="actual" placeholder="Contraseña nueva" required />
                            </div>
                            <div className="input-wrapper">
                                <label htmlForm="password-change-new" className="hide">Repita contraseña</label>
                                <input type="password" id="password-change-new"
                                    onChange={e => this.setState({ newPassword: e.target.value })}
                                    name="nueva" placeholder="Repita contraseña" required />
                            </div>
                            <div className="input-wrapper type-submit text-center">
                                <input type="button" onClick={() => this.changePass()} value="Guardar" className="button invert" />
                            </div>
                        </form>
                        <div onClick={() => this.toggleChangePass()} className="close"><span></span></div>
                    </div>
                </section>
            </div>
        );
    }
}


export default withRouter(Login);
