import React from 'react';
import { withRouter } from "react-router-dom";
import { InvestInPbg, PbgLocation, GalleryPreFooter } from './Home';
import OliverHelper, { GlobalVars } from '../../utils/Oliverhelper';
import moment from 'moment';
import 'moment/locale/es';


class Proyectos extends React.Component {
  constructor(props) {
    super(props);
    this.state = { projects: [], bannersFooter: [] };
  }

  componentDidMount() {
    document.title = 'Emprendimientos Actuales y Futuros - PBG';
    OliverHelper.ClientAPI.getProjects().then(response => {
      this.setState({ projects: response.emprendimientos });
    });
    OliverHelper.ClientAPI.getProjects({ home_top_slider: 1 }).then(response => {
      this.setState({ bannersFooter: response.bannersFooter });
    });
  }


  getImageByType = (type, digitales) => {
    let digital = false;
    digitales.map(eachDigital => {
      //console.log(eachDigital.tipo.toLowerCase(), '  con ', type.toLowerCase());
      if (eachDigital.tipo.toLowerCase() == type.toLowerCase()) {
        digital = eachDigital;
        return;
      }
    });
    return digital;
  }


  goTo = (project) => this.props.history.push('/proyecto/' + project.emprendimiento.nombre.replace(' ', '-') + '/' + project.emprendimiento.id);

  render() {

    return (
      <div>
        <section className="site-section section-projects">
          <div className="row">
            <h1 className="title section-title is-underline show-for-large">Proyecto Actuales</h1>

            <div className="list">
              <div className="grid-x">
                {this.state.projects.map(eachProject => <EachProject goTo={this.goTo} getImageByType={this.getImageByType} project={eachProject} />)}
              </div>
            </div>
          </div>
        </section>

        <InvestInPbg />
        <GalleryPreFooter data={this.state.bannersFooter} />
      </div>
    );
  }
}


const EachProject = ({ project, goTo, getImageByType }) => {
  let backImage = getImageByType('emprendimiento_slider', project.digitales);
  let logo = getImageByType('logo', project.digitales);
  if (!backImage) return null;

  let digital = backImage.image_path;
  let domicilioProject = project.domicilio[0];

  return (
    <div className="small-12 medium-6 cell item animated fadeInUp" data-animation="fadeInUp">
      <a href="#" onClick={(evt) => goTo(project)} className="cta">
        <div className="image">
          {logo ? <div className="overlay">
            <img src={logo.image_path} className="scale-down-50" />
          </div> : ''}
          <img src={digital} />
        </div>
        <div className="text">
          <h2 className="title">{project.emprendimiento.nombre}</h2>
          <p className="info"> {domicilioProject.calle ? domicilioProject.calle + ' ' + domicilioProject.numero : ''} / {GlobalVars.showDate(project)} {project.unidadMayorMts ? `/ Hasta ${project.unidadMayorMts} m²` : ''}</p>
          <hr />
        </div>
      </a>
    </div>)
}

export default withRouter(Proyectos);
