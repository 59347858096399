import React from 'react';
import { withRouter } from "react-router-dom";
import '../Styling/accountStyles.css';
import OliverHelper from '../../utils/Oliverhelper';
import AuthService from '../../utils/AuthService';
import { AlertWeb } from '../Tools/Utils';
import swal from 'sweetalert';


const auth = new AuthService();

class MiCuenta extends React.Component {
  constructor(props) {
    super(props);
    this.state = { saveVisible: false, user: {}, alertShow: false, client: {}, visibleChangePass: false };
  }

  componentDidMount() {
    let logged = auth.loggedIn();
    if (!logged || logged == 'undefined') {
      this.props.history.push('/mis-unidades-pbg');
      return;
    }


    document.title = 'Mi Cuenta';
    OliverHelper.ClientAPI.user().then((response) => {
      console.log('response login', response);
      if (response) {
        this.setState({ user: response.user, client: response.client });
      }
    })
  }

  toggleSaveVisible = () => this.setState({ saveVisible: !this.state.saveVisible })
  toggleChangePass = () => this.setState({ visibleChangePass: !this.state.visibleChangePass })

  setClientData = (key, val) => {
    let aux = this.state.client;
    aux[key] = val;
    this.setState({ client: aux });
  }

  setUserData = (key, val) => {
    let aux = this.state.user;
    aux[key] = val;
    this.setState({ user: aux });
  }

  saveData = () => {
    OliverHelper.ClientAPI.saveProfile({ user: this.state.user, client: this.state.client }).then(response => {
      if (response.status === false) {
        swal("Fallamos!", response.text, "warning");
      } else {
        swal("Perfecto!", "Tus datos han sido guardados", "success");
      }
    })
  }


  changePass = () => {
    OliverHelper.ClientAPI.changePass({ password: this.state.newPassword, actualPassword: this.state.actualPassword }).then(response => {
      //alert(response.text);
      if (response.status) {
        swal("Perfecto!", response.text, "success");
      } else {
        swal("Fallamos!", response.text, "warning");
      }

      this.toggleChangePass();
    })
  }


  render() {
    console.log('user', this.state.user);
    return (
      <div>
        <section className="interface-section first full-height dark">
          <div className="inner">
            <header className="title-wrapper">
              <h1 className="title">Mi Cuenta</h1>
              <span className="icon icon-presenter"></span>
            </header>
            <form className="account-form" action="json/account.json" method="GET">
              <div className="input-wrapper">
                <label htmlForm="account-name">Nombre</label>
                <input type="text" id="account-name" value={this.state.client.nombre}
                  onChange={(e) => this.setClientData('nombre', e.target.value)}
                  name="nombre" placeholder="Nombre" required
                  readOnly={!this.state.saveVisible}
                />
              </div>
              <div className="input-wrapper">
                <label htmlForm="account-name">Apellido</label>
                <input type="text" id="account-name" value={this.state.client.apellido}
                  onChange={(e) => this.setClientData('apellido', e.target.value)}
                  name="nombre" placeholder="Nombre" required
                  readOnly={!this.state.saveVisible}
                />
              </div>
              <div className="input-wrapper">
                <label htmlForm="account-dni">DNI</label>
                <input type="text" id="account-dni"
                  onChange={(e) => this.setClientData('dni', e.target.value)}
                  value={this.state.client.dni} name="dni" placeholder="DNI" required
                  readOnly={!this.state.saveVisible}
                />
              </div>
              <div className="input-wrapper">
                <label htmlForm="account-email">Email</label>
                <input type="email" id="account-email"
                  onChange={(e) => this.setUserData('email', e.target.value)}
                  value={this.state.user.email} name="email" placeholder="Email" required
                  readOnly={!this.state.saveVisible}
                />
              </div>
              <div className="input-wrapper">
                <label htmlForm="account-phone">Tel.</label>
                <input type="tel" id="account-phone"
                  onChange={(e) => this.setClientData('telefono', e.target.value)}
                  value={this.state.client.telefono} name="telefono" placeholder="Tel." required
                  readOnly={!this.state.saveVisible}
                />
              </div>
              <div className="input-wrapper type-submit">
                {this.state.alertShow ?
                  <AlertWeb msj="Datos guardados" />
                  :
                  <input type="button" onClick={() => this.saveData()} value="Guardar" className={`button invert  ${!this.state.saveVisible ? 'hide' : ''}`} />
                }
              </div>
              <span className="toggler" onClick={() => this.toggleSaveVisible()} data-edit-account>Editar mis datos</span><br />
              <span className="toggler" onClick={() => this.toggleChangePass()} data-change-password>Cambiar mi contraseña</span>
            </form>
          </div>
        </section>
        <section className={`password-change ${this.state.visibleChangePass ? 'visible' : ''}`}>
          <div className="inner">
            <h1 className="title">Datos de acceso</h1>
            <form className="login-form" action="json/password-change.json" method="GET">
              <div className="input-wrapper">
                <label htmlForm="password-change-current" className="hide">Contraseña Actual</label>
                <input type="password" id="password-change-current"
                  onChange={e => this.setState({ actualPassword: e.target.value })}
                  name="actual" placeholder="Contraseña Actual" required />
              </div>
              <div className="input-wrapper">
                <label htmlForm="password-change-new" className="hide">Contraseña Nueva</label>
                <input type="password" id="password-change-new"
                  onChange={e => this.setState({ newPassword: e.target.value })}
                  name="nueva" placeholder="Contraseña Nueva" required />
              </div>
              <div className="input-wrapper type-submit text-center">
                <input type="button" onClick={() => this.changePass()} value="Actualizar" className="button invert" />
              </div>
            </form>
            <div onClick={() => this.toggleChangePass()} className="close"><span></span></div>
          </div>
        </section>
      </div>
    );
  }
}


export default withRouter(MiCuenta);
