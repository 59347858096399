import decode from 'jwt-decode';
import axios from 'axios';
const TOKEN_NAME = 'pbg_token';
const LOGIN_ENDPOINT = 'login_controller/validar/';
const FORGOT_PASSWORD_ENDPOINT = 'login_controller/forgot/';



axios.interceptors.request.use(function (config) {
    config.metadata = { startTime: new Date() }; return config;
}, function (error) { return Promise.reject(error); });


axios.interceptors.response.use(function (response) {
    response.config.metadata.endTime = new Date()
    response.duration = response.config.metadata.endTime - response.config.metadata.startTime
    return response;
}, function (error) {
    error.config.metadata.endTime = new Date();
    error.duration = error.config.metadata.endTime - error.config.metadata.startTime;
    return Promise.reject(error);
});

export default class AuthService {
    // Initializing important variables
    constructor(domain) {



        this.login = this.login.bind(this)
        this.getProfile = this.getProfile.bind(this)
    }

    getDomainEndpoint = () => { return process.env.REACT_APP_API_URI; }


    login(user, password) {
        // Get a token from api server using the fetch api
        const params = { user: user, password: password };
        return this.postPublic(LOGIN_ENDPOINT, params)
            .then((responseJson) => {
                if (responseJson.data['api-key'] !== undefined && responseJson.data['api-key'].length > 0) {
                    this.setToken(responseJson.data['api-key']);
                    this.registerForPushNotificationsAsync(responseJson.data['api-key']);
                }
                return responseJson.data;
            })
            .catch((error) => {
                console.error('post helper error ' + error);
            });
    }




    setTokenUser(token) {
        this.setToken(token);
    }

    async forgotPassword(email) {
        const params = { email: email };
        var domain = await this.getDomainEndpoint();
        const url = domain + FORGOT_PASSWORD_ENDPOINT;
        console.log(params, url);
        return axios.post(url, params)
            .then((responseJson) => {
                if (responseJson.data['api-key'] !== undefined && responseJson.data['api-key'].length > 0) {
                    this.setToken(responseJson.data['api-key']);
                    this.registerForPushNotificationsAsync(responseJson.data['api-key']);
                }
                return responseJson;
            })
            .catch((error) => {
                console.error('post helper error ' + error);
            });
    }

    loggedIn() {
        return this.getToken()
    }

    isTokenExpired(token) {
        console.log('isTokenExpired funtion');
        try {
            const decoded = decode(token);
            if (decoded.exp < Date.now() / 1000) { // Checking if token is expired. N
                return true;
            }
            else
                return false;
        }
        catch (err) {
            return false;
        }
    }

    async getLink(url, params = false) {
        if (params) {
            params += '&X-API-KEY=' + this.getToken();
        } else {
            params = '?X-API-KEY=' + this.getToken();
        }
        var domain = await this.getDomainEndpoint();
        url = domain + url + params;
        return url;
    }

    async getPublic(url, params = false) {
        var domain = await this.getDomainEndpoint();
        var url_token = domain + url;
        var response = axios.get(url_token, { params: params })
            .then((responseJson) => {
                responseJson.data.duration = responseJson.duration;
                return responseJson.data;
            })
            .catch((error) => {
                console.log('url', url_token, 'params', params);
                console.error('get helper error ' + error, url_token, params);
            });
        return response;
    }

    async get(url, params = false) {
        var tokenSinFiltro = (await this.getToken());
        /* if (!tokenSinFiltro) {
             return;
         }*/


        var token = tokenSinFiltro ? tokenSinFiltro.replace(/["']/g, "") : '';
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }
        /*if (params) {
            params['X-API-KEY'] = token;
        } else {
            params = { 'X-API-KEY': token };
        }*/
        var domain = await this.getDomainEndpoint();
        //var url_token = domain + url + 'X-API-KEY/' + token;
        var url_token = domain + url;
        var response = axios.get(url_token, { params: params })
            .then((responseJson) => {
                responseJson.data.duration = responseJson.duration;
                return responseJson.data;
            })
            .catch((error) => {
                console.log('url', url_token, 'params', params);
                console.error('get helper error ' + url_token + error);
            });
        return response;
    }

    async postPublic(url, data) {
        var tokenSinFiltro = (await this.getToken());
        var domain = await this.getDomainEndpoint();
        var url_token = domain + url;
        if (tokenSinFiltro) {
            let token = tokenSinFiltro.replace(/["']/g, "");
            axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` }
            url_token += 'X-API-KEY/' + token;
        }

        console.log('url post no token', url_token, 'data', data);
        var response = axios.post(url_token, data)
            .then((responseJson) => {
                responseJson.data.duration = responseJson.duration;
                return responseJson.data;
            })
            .catch((error) => {
                console.error('post helper error ' + error);
            });
        return response;
    }

    async post(url, data) {
        var tokenSinFiltro = (await this.getToken());
        //   if (!tokenSinFiltro) return;

        var token = tokenSinFiltro ? tokenSinFiltro.replace(/["']/g, "") : '';
        if (token) {
            axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` };
        }
        data['url'] = window.location.href;

        var domain = await this.getDomainEndpoint();
        //var url_token = domain + url + 'X-API-KEY/' + token;
        var url_token = domain + url;
        console.log('url post token', url_token, 'data', data);
        var response = axios.post(url_token, data)
            .then((responseJson) => {
                responseJson.data.duration = responseJson.duration;
                return responseJson.data;
            })
            .catch((error) => {
                console.error('post helper error ' + error);
                return error;
            });
        return response;
    }

    setToken(idToken) {
        // Saves user token to localStorage
        localStorage.setItem(TOKEN_NAME, idToken)
    }

    getToken() {
        var response = localStorage.getItem(TOKEN_NAME)
        return response
    }

    logout() {
        // Clear user token and profile data from localStorage
        localStorage.removeItem(TOKEN_NAME);
        if (navigator.serviceWorker) {
            navigator.serviceWorker.removeEventListener('message', (event) => { console.log('cerrando listener de service worker') });
            navigator.serviceWorker.getRegistration().then(registration => registration ? registration.unregister() : '');
        }

    }

    getProfile() {
        // Using jwt-decode npm package to decode the token
        return decode(this.getToken());
    }

    isProfile(name) {
        return this.getToken() && this.getProfile().rol === name ? true : false;
    }


    filterRoutes(routes) {
        var finalRoutes = [];

        finalRoutes = routes.publicRoutes

        return finalRoutes;
    }

}
