import 'moment/locale/es';
import React from 'react';
import { withRouter } from "react-router-dom";
import OliverHelper, { GlobalVars } from '../../utils/Oliverhelper';
import '../Styling/accountStyles.css';
import bgMisUnidades from '../Styling/images/home/bg-background-mis-unidades.jpg'

import AuthService from '../../utils/AuthService';

const auth = new AuthService();


class MiUnidad extends React.Component {
  constructor(props) {
    super(props);
    this.state = { dataProject: [], imageProject: [], avances: [], dataEmp: [], empProject: [] };
  }

  componentDidMount() {
    let logged = auth.loggedIn();
    console.log('logged', logged);
    let empId = this.props.match.params.id;
    let id = this.props.match.params.id;
    if (!logged || logged == 'undefined') {
      this.props.history.push('/mis-unidades-pbg?section=mi-unidad&name=' + this.props.match.params.any + '&emp_id=' + empId);
      return;
    }



    OliverHelper.ClientAPI.misUnidades({ id: 1, id_emprendimiento: empId }).then(response => {   // <---- HARDCODEADO el ID
      document.title = 'Mis unidades en ' + response.emprendimiento.nombre;
      this.setState({ dataProject: response.misUnidades, avances: response.avance_obra, dataEmp: response.emprendimiento }, () => {

        const script = document.createElement('script');
        script.src = `https://cdnjs.cloudflare.com/ajax/libs/fancybox/3.5.7/jquery.fancybox.min.js`;
        document.body.appendChild(script);

      });
    })
  }

  getType = (typeToSearch, digitales, typeArray = false) => {
    //console.log('digitales', digitales)
    var digitalWanted = !typeArray ? {} : [];
    digitales.map(eachDigital => {
      //console.log('PARA MAPEAR', eachDigital.tipo, typeToSearch)
      if (eachDigital.tipo === typeToSearch) {
        if (!typeArray) {
          digitalWanted = eachDigital
        } else {
          digitalWanted.push(eachDigital);
        }

      }
    });
    return digitalWanted;
  }

  /*parseTipologias = (unidades) => {
    let tipologias = [];
    unidades.map(eachUnidad => tipologias.push(eachUnidad.tipologia));
    return tipologias;
  }*/

  setPlanToSee = (path) => this.setState({ planoPath: path })


  render() {
    //let tipologias = this.parseTipologias(this.state.dataEmp.unidades);
    let empBrochure = {};
    let avanceObra = this.state.avance_obra;

    let amenitiesProject = [];
    let tipologiasProject = [];
    let avanceObraResumen = {};
    let backImage = {};

    if (this.state.dataEmp != undefined && this.state.dataEmp.digitales != undefined && this.state.dataEmp.digitales.length > 0) {
      empBrochure = this.getType('Brochure', this.state.dataEmp.digitales)
      backImage = this.getType('Background', this.state.dataEmp.digitales)
      avanceObraResumen = this.getType('resumen_avance', this.state.dataEmp.digitales);
    }
    if (this.state.dataEmp != undefined && this.state.dataEmp.amenities != undefined && this.state.dataEmp.amenities.length > 0) {
      amenitiesProject = this.state.dataEmp.amenities
    }
    if (this.state.dataEmp != undefined && this.state.dataEmp.tipologias != undefined && this.state.dataEmp.tipologias.length > 0) {
      tipologiasProject = this.state.dataEmp.tipologias
    }



    let hasExtra = false;
    return (
      <div>
        <section className="interface-section top-content cover"
          style={{ backgroundImage: `url(${backImage.image_path})`, backgroundSize: 'cover', height: 'auto' }}
        //data-interchange="[images/background-unit-detail.jpg, portrait], [images/background-unit-detail-landscape.jpg, landscape]"
        >
          <div className="inner">
            <div>
              <h1 className="title">{this.state.dataEmp.nombre}</h1>
              <p>{GlobalVars.showDate(this.state.dataEmp.fecha_entrega, true)} / {this.state.dataEmp.domicilio ? this.state.dataEmp.domicilio.barrio : ''}</p>
              {empBrochure && empBrochure.image_path != undefined
                ?
                <button className="button transparent" onClick={() => window.open(empBrochure.image_path)}>
                  Ver Brochure
              </button>
                : null}
            </div>

          </div>
        </section>


        <section className={`interface-section dark ${this.state.planoPath ? 'floorplan-open' : ''} `}>
          <div className="inner">
            <header className="title-wrapper primary small">
              <h1 className="title">Unidad adquirida</h1>
            </header>

            <div className="grid-x units layout-owned">

              {this.state.dataProject.map(eachUnidad => {
                if (eachUnidad.tipologia.toLowerCase() == 'cochera' || eachUnidad.tipologia.toLowerCase() == 'baulera') {
                  hasExtra = true;
                }
                return <EachUnidad setPlanToSee={this.setPlanToSee} unidad={eachUnidad} getDigitalByType={this.getType} />
              })}






              {hasExtra ?
                <div className="small-12 large-4 cell item">
                  <ul className="extras">
                    {this.state.dataProject.map(eachUnidad =>
                      eachUnidad.tipologia.toLowerCase() == 'cochera' ? <li>{eachUnidad.descripcion + ' ' + eachUnidad.unidad} <i className="icon icon-car"></i></li>
                        : (eachUnidad.tipologia.toLowerCase() == 'baulera' ? <li>{eachUnidad.descripcion + ' ' + eachUnidad.unidad} <i className="icon icon-suitcase"></i></li>
                          : null)
                    )}
                  </ul>
                </div>
                : null}


              <p style={{ fontSize: 11, color: 'white', margin: 25 }}>
                Toda la información sobre planos, medidas y superficies, y fotografías suministrada en este sitio tiene carácter meramente orientativo y provisorio, y está sujeto a modificaciones, adecuaciones y replanteos de tipología, tamaño y características de las unidades como de los servicios y espacios comunes del edificio
              </p>
            </div>
          </div>
          <div className="floorplan-image">
            <div onClick={() => this.setPlanToSee(null)} className="floorplan-close"><span></span></div>
            {this.state.planoPath && <img src={this.state.planoPath} />}
          </div>
        </section>

        <section className="interface-section">
          <div className="inner">
            <header className="title-wrapper primary small">
              <h1 className="title">resumen del proyecto</h1>
            </header>
            <div className="detail">
              <h2>Detalle</h2>
              <p>
                {this.state.dataEmp.domicilio ? this.state.dataEmp.domicilio.calle : ''} {this.state.dataEmp.domicilio ? this.state.dataEmp.domicilio.numero : ''}
                {this.state.dataEmp.domicilio ? this.state.dataEmp.domicilio.barrio : ''} {this.state.dataEmp.domicilio ? this.state.dataEmp.domicilio.ciudad : ''}
                <br />{tipologiasProject.map(eachtipo => <div>{eachtipo}</div>)}
                {this.state.dataEmp ? `Posesión Estimada: ${GlobalVars.showDate(this.state.dataEmp.fecha_entrega, true)}` : ''}
              </p>
            </div>
            <div className="detail">
              <h2>Amenities</h2>
              <p>
                {amenitiesProject.map(eachAmenitie => <span>{eachAmenitie.nombre}<br /></span>)}
              </p>
            </div>
            <div className="links">
              {empBrochure.image_path && <a href={empBrochure.image_path} target="_blank">Descargar Brochure</a>}
              <br />
              <a href={`/proyecto/${this.state.dataEmp.id}`} target="_blank">Proyecto completo en sitio web</a>
            </div>
          </div>
        </section>
        <section className="interface-section">
          <div className="inner">
            <header className="title-wrapper primary small">
              <h1 className="title">Avance de obra</h1>
            </header>
            <div className="work-progress">
              {avanceObraResumen.image_path != undefined ? <a href={avanceObraResumen.image_path} className=" " target="_blank">Descargar último informe de obra {GlobalVars.showDate(avanceObraResumen.fecha_carga, true)}</a> : null}
              <div className="items">

                {this.state.avances.map((eachArray, i) => {
                  return (


                    <div className="item">
                      <h2>{GlobalVars.showDate(eachArray[0].fecha_carga, true, 'DD MMMM YYYY')}<span className="icon"></span></h2>
                      <div className="images">

                        {eachArray.map(eachAvance => {
                          return <div><a target="_BLANK" href={eachAvance.image_path} data-fancybox={`work-1${i}`}><img src={eachAvance.image_path} /></a></div>
                        })}


                      </div>
                    </div>
                  )
                })}

              </div>

            </div>
          </div>
        </section>
        <section className="interface-section primary section-maximize">
          <div className="inner"

            style={{ backgroundImage: `url(${bgMisUnidades})` }}
            data-events="resize">
            <div className="row">
              <div className="grid-x grid-padding-x align-center">
                <div className="small-12 large-8 cell">
                  <h1 className="title section-title invert is-underline">Suma una <br class="hide-for-large" />nueva inversión</h1>
                  <div className="section-description">
                    <p>Si estás buscando potenciar tus ingresos y querés hacer una inversión con tu propiedad o lote hablá con nosotros. En PBG estamos constantemente buscando la materia prima para nuestros próximos proyectos.</p>
                    <a href="/nueva-inversion" className="button" data-animation="fadeInUp">Ver +</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const EachUnidad = ({ unidad, getDigitalByType, setPlanToSee }) => {
  let digitalPlanta = getDigitalByType('Planta', unidad.digitales);
  if (unidad.tipologia.toLowerCase() != 'baulera' && unidad.tipologia.toLowerCase() != 'cochera') {
    return (
      <div className="small-12 large-4 cell item">
        <div className="content">
          <h2>UNIDAD {unidad.unidad} - PISO {unidad.piso}</h2>
          <h3>{unidad.tipologia}</h3>
          <ul>
            <li>Sup. Cubierta <span className="value">{unidad.mts_cubiertos} m<sup>2</sup></span></li>
            <li>Sup. Semi descubierta <span className="value">{unidad.mts_nocubiertos} m<sup>2</sup></span></li>
            <li>Sup. Amenities <span className="value">{unidad.superficie_amenities} m<sup>2</sup></span></li>
            <li>Sup. Total <span className="value">{(parseFloat(unidad.mts_cubiertos) + parseFloat(unidad.mts_nocubiertos) + parseFloat(unidad.superficie_amenities)).toFixed(2)} m<sup>2</sup></span></li>
          </ul>
          <div className="text-center">
            {unidad.planoPath != undefined
              ?
              <button className="button view-floorplan transparent" onClick={() => {
                if (unidad.planoPath.image_path !== undefined) {
                  setPlanToSee(unidad.planoPath.image_path);
                } else {
                  setPlanToSee(unidad.planoPath);
                }
              }
              }>
                Ver plano
          </button>
              :
              null}

          </div>
        </div>
        {unidad.cliente_unidad.saldo && <p className="balance">Saldo al día de hoy: {unidad.cliente_unidad.moneda} {unidad.cliente_unidad.saldo}</p>}
        {unidad.cliente_unidad.image_path.length > 32 && <a href={unidad.cliente_unidad.image_path} target="_blank" className="button download-settlement">Descargar última liquidación</a>}
      </div>
    )
  }
  return null;
}


export default withRouter(MiUnidad);
