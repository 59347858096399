import React from 'react';
import OliverHelper, { GlobalVars } from '../../utils/Oliverhelper';

import HorizontalSlider from './HorizontalCarousel';

import administracion from '../Styling/images/home/icons_administracion.png';
import busqueda from '../Styling/images/home/icons_busqueda.png';
import comercializacion from '../Styling/images/home/icons_comercializacion.png';
import desarrollo from '../Styling/images/home/icons_desarrollo.png';
class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = { banners: [], width: window.innerWidth, siteContact: false, middleProjects: [], topProjects: [], bannersFooter: [] };
    console.log('props', this.props);
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight, scrollY: '' });
  };

  componentDidMount() {
    this.getTopProjects();
    this.getMiddleProjects();
  }

  getTopProjects = async () => {
    console.log('quiere buscar rpodcutos');
    OliverHelper.ClientAPI.getProjects({ home_top_slider: 1 }).then(response => {
      console.log('home slider',response);
      this.setState({ topProjects: response.emprendimientos, banners: response.banners, bannersFooter: response.bannersFooter })
    });
  }

  getMiddleProjects = () => {
    OliverHelper.ClientAPI.getProjects({ home_middle_slider: 1 }).then(response => {
      console.log('response',response);
      this.setState({ middleProjects: response.emprendimientos }, () => {
        window.loadSliderHome();
      })
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
    window.removeEventListener('scroll', this.listenToScroll)
  }

  listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight

    const scrolled = (winScroll / height) * 100

    this.setState({
      scrollY: scrolled,
    })
    //console.log('el scroll es: ',this.state.scrollY)
  }

  openNavBar = () => {
    document.body.className = 'nav-shown';
  }


  closeNavBar = () => {
    document.body.className = '';
  }

  getImageByType = (type, digitales) => {
    let digital = false;
    digitales.map(eachDigital => {
      //console.log(eachDigital.tipo.toLowerCase(), '  con ', type.toLowerCase());
      if (eachDigital.tipo.toLowerCase() == type.toLowerCase()) {
        digital = eachDigital;
        return;
      }
    });
    return digital;
  }




  render() {
    
    let cant = 0;
    let es_mobile = window.screen.width < 800 ? true : false;
    const bannerHomeTop =  this.state.banners.filter(eachBanner => eachBanner.tipo == 'homeTop');
    const bannerHomeDestacado = this.state.banners.filter(eachBanner => eachBanner.tipo == 'Home' || eachBanner.tipo == '');

    console.log('STATE BANNERs', this.state.banners)
    console.log('BANNER-TOP-HOME', bannerHomeTop)
    console.log('BANNER-DESTACADO', bannerHomeDestacado)

    return (
      <div>
        <div className="top-content">
          <div className="cover-slider">
            <div className="items"> 

              <BannerTitles banner={bannerHomeTop}/>           

              {this.state.topProjects.map((eachProject, i_back) => {
                let logo = this.getImageByType('logo', eachProject.digitales);
                if (!logo) return;
                return (
                  <div key={i_back} className={`item active ${i_back == 0 ? 'active' : ''}`}>
                    <div className="text">
                      <p className="text-top">{eachProject.emprendimiento.nuevo_lanzamiento ? 'Nuevo lanzamiento' : ''}</p>
                      <img src={logo.image_path} className="logo scale-down-60 medium-scale-down-50" />
                      <p className="subTitle">{eachProject.emprendimiento.txt_comercial_tipologia}</p>
                      <p className="subTitle"><span>Entrega</span> {GlobalVars.showDate(eachProject)}</p>
                      <a href={`/proyecto/${eachProject.emprendimiento.nombre.replace(' ', '-')}/${eachProject.emprendimiento.id}`} class="button bottom-on-large" >Ver proyecto</a>
                    </div>
                  </div>
                )
              })}
              
              <BannerTitles banner={bannerHomeDestacado}/> 

            </div>
          </div>
          <div className="cover-slider-images">
            <div className="items">             
              
            <BannersImage banner={bannerHomeTop} />

              {this.state.topProjects.map((eachProject, i_back) => {
                let backImage = this.getImageByType('background', eachProject.digitales);
                if (!backImage) return;
                //  console.log('back image de', eachProject.emprendimiento.name, ' ', backImage, eachProject.digitales)
                return (<div key={`project-${i_back}`} className={`item-image cover ${i_back == 0 ? 'active' : ''}`}
                  style={{ background: `url(${backImage.image_path}) no-repeat`, backgroundSize: 'cover' }}
                >
                </div>)
              })}

              <BannersImage banner={bannerHomeDestacado} /> 

            </div>
          </div>
          <div className="social show-for-large">
            <a href={GlobalVars.LINK_IG} target="_blank"><img src="images/icon-instagram-white.png" /></a>
            <a href={GlobalVars.LINK_FB} target="_blank"><img src="images/icon-facebook-white.png" /></a>
          </div>
        </div>
        <section className="site-section section-introduction">
          <div className="row">
            <div className="grid-x grid-padding-x align-center">
              <div className="small-11 medium-8 large-7 cell">
                <h1 className="title section-title is-underline">Creamos proyectos<br />de alto valor</h1>
                <div className="section-description">
                  <p>Somos un grupo joven pero con amplia experiencia en Argentina y el exterior, Nos concentramos en buscar las mejores alternativas para que tu inversión crezca, de forma segura, en las mejores ubicaciones de la ciudad.</p>
                  <p><em>Estos son los servicios que brindamos:</em></p>
                </div>
              </div>
            </div>

            <div className="services">
              <div className="grid-x">

                <div className="small-6 medium-3 cell">
                  <div className="item animated fadeIn appear-animation" data-animation="fadeIn" data-animation-duration="1500" data-animation-delay="300">
                    <img src={comercializacion} alt="Icono Comercialización de Propiedades" />
                    <h2 className="title">Comercialización <span>de Propiedades</span></h2>
                  </div>
                </div>

                <div className="small-6 medium-3 cell">
                  <div className="item animated fadeIn appear-animation" data-animation="fadeIn" data-animation-duration="1500" data-animation-delay="900">
                    <img src={administracion} alt="Icono Administración de Obras" />
                    <h2 className="title">Administración <span>de Obras</span></h2>
                  </div>
                </div>

                <div className="small-6 medium-3 cell">
                  <div className="item animated fadeIn appear-animation" data-animation="fadeIn" data-animation-duration="1500" data-animation-delay="600">
                    <img src={desarrollo} alt="Icono Desarrollo de Proyectos Edilicios" />
                    <h2 className="title">Desarrollo <span>de Proyectos Edilicios</span></h2>
                  </div>
                </div>

                <div className="small-6 medium-3 cell">
                  <div className="item animated fadeIn appear-animation" data-animation="fadeIn" data-animation-duration="1500" data-animation-delay="1200">
                    <img src={busqueda} alt="Icono Búsqueda de Terrenos" />
                    <h2 className="title">Búsqueda <span>de Terrenos</span></h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="site-section section-last-projects">
          <div className="row fluid">
            <h1 className="title section-title is-underline">Ultimos proyectos</h1>
            <div className="text-center">
              <a href="proyectos" className="button">Ver Todos</a>
            </div>

            <div className="last-projects animated fadeIn appear-animation" data-animation="fadeIn" data-animation-delay="">
              <div className="items">
                {/**{this.state.middleProjects.map(eachProject =>
                  <MiddleProject getImageByType={this.getImageByType} project={eachProject} />
                )}*/}
                <HorizontalSlider dataSlider={this.state.middleProjects.map(eachProject =>
                  <MiddleProject getImageByType={this.getImageByType} project={eachProject} />
                )} />
              </div>
              {/*<div className="arrow-prev slick-arrow">Proyecto <br />anterior</div>
              <div className="arrow-next slick-arrow">Siguiente <br />proyecto</div>*/}
            </div>
          </div>
        </section>



        <ImproveRental />
        <AboutPbg />
        <InvestInPbg />
        <PbgLocation />
        <GalleryPreFooter data={this.state.bannersFooter} />



      </div>
    );
  }
}


const BannersImage = ({ banner }) => {
  return banner.map((eachProject, i_back) => {
    return (
      <div key={`banner-${i_back}`} className={`item-image cover `}
        style={{ background: `url(${eachProject.image_path}) no-repeat`, backgroundSize: 'cover' }}
      >
      </div>
    );
  });
}

const BannerTitles = ({ banner }) => {
  return banner.map((eachBanner, i) => {
    return (
      <div key={i} className="item ">
        <div className="text">
          <h2 className="title">{eachBanner.titulo}</h2>
          <p className="subTitle">{eachBanner.subtitulo}</p>
          {/* <a href="#" className="button bottom-on-large" onClick={() => GlobalVars.openSiteContact()} >Contactanos</a> */}
        </div>
      </div>
    )
  })   
}



const MiddleProject = ({ project, getImageByType }) => {

  let backImage = getImageByType('emprendimiento_slider', project.digitales);
  if (!backImage) return null;
  //console.log('middle project', backImage);
  let digital = backImage.image_path;

  let unidadesName = project.tipologias.map((eachTipologia, i) => (i > 0 ? "," : "") + eachTipologia);



  return (
    <div className="item">
      <a href={`proyecto/${project.emprendimiento.nombre.replace(' ', '-')}/${project.emprendimiento.id}`} className="cta">
        <img src={digital} width="700px" alt={"Foto proyecto Terre Soho" + project.emprendimiento.nombre} />
        <div className="text">
          <p className="info">{GlobalVars.showDate(project)} / {project.domicilio[0].barrio}</p>
          <h2>{project.emprendimiento.nombre}</h2>
          <p>Unidades de {unidadesName}.<br />{project.emprendimiento.estado_emprendimiento}</p>
          <span className="view-more"><i className="icon"></i> Ver +</span>
        </div>
      </a>
    </div>)
}

const ImproveRental = ({ }) => (
  <section className="site-section primary section-maximize">
    <div className="inner">
      <div className="row">
        <div className="grid-x grid-padding-x align-center">
          <div className="small-12 large-8 cell">
            <h1 className="title section-title invert is-underline">Maximizá el rendimiento de <br />tu casa o tu terreno</h1>
            <div className="section-description">
              <p>Si estás buscando potenciar tus ingresos y querés hacer una inversión con tu propiedad o lote hablá con nosotros. En PBG estamos constantemente buscando la materia prima para nuestros próximos proyectos.</p>
              <a href="/vende-tu-terreno" className="button animated fadeInUp appear-animation" data-animation="fadeInUp">Ver +</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

const AboutPbg = ({ }) => (
  <section className="site-section section-about">
    <div className="inner"
      style={{ background: 'url(../images/home/background-about-landscape.jpg)' }}
      data-interchange="[images/home/background-about.jpg, portrait], [images/home/background-about-landscape.jpg, landscape]">
      <div className="row">
        <h1 className="title section-title invert is-underline">Sobre PBG</h1>

        <div className="items">
          <div className="grid-x">

            <div className="small-12 medium-4 cell item animated fadeIn appear-animation" data-animation="fadeIn" data-animation-duration="1500" data-animation-delay="300">
              <img src="images/home/icon-about-lightbulb.png" alt="Icono Misión" className="icon" />
              <h2 className="title">Misión</h2>
              <p>Somos un grupo desarrollador dedicado a los negocios inmobiliarios que sostengan la relación con el inversor y la potencie hacia el futuro.</p>
            </div>

            <div className="small-12 medium-4 cell item animated fadeIn  appear-animation" data-animation="fadeIn" data-animation-duration="1500" data-animation-delay="600">
              <img src="images/home/icon-about-handshake.png" alt="Icono Valor" className="icon" />
              <h2 className="title">Valor</h2>
              <p>Creemos en que los buenos negocios son el fruto de una relación de confianza y la retroalimentación de la misma a lo largo de los años.</p>
            </div>

            <div className="small-12 medium-4 cell item animated fadeIn  appear-animation" data-animation="fadeIn" data-animation-duration="1500" data-animation-delay="900">
              <img src="images/home/icon-about-medal.png" alt="Icono Trayectoria" className="icon" />
              <h2 className="title">Trayectoria</h2>
              <p>Contamos con más de 15 años de experiencia en el mercado inmobiliario.</p>
            </div>

          </div>
        </div>
      </div>
    </div>
  </section>
)

const GalleryPreFooter = ({ data }) => {

  return (
    <section className="bottom-gallery">
      {/** si es mobile */}
      <div className="hide-for-large grid-x small-up-3 medium-up-5">
        {data.map((eachBanner, i) =>
          i < 3 &&
          <div key={i} className="cell animated fadeInUp appear-animation" data-animation="fadeInUp" data-animation-delay="200">
            <a href={GlobalVars.LINK_IG} className="cta cover"
              style={{ backgroundImage: `url(${eachBanner.image_path})` }}>
              <img src="images/icon-instagram-white.png" className="icon" /></a>
          </div>
        )}
      </div>
      {/** si es desktop */}
      <div className="hide-for-small-only grid-x small-up-3 medium-up-5">
        {data.map((eachBanner, i) =>
          <div key={i} className="cell animated fadeInUp appear-animation" data-animation="fadeInUp" data-animation-delay="200">
            <a href={GlobalVars.LINK_IG} className="cta cover"
              style={{ backgroundImage: `url(${eachBanner.image_path})` }}>
              <img src="images/icon-instagram-white.png" className="icon" /></a>
          </div>
        )}
      </div>
    </section>)
}
const PbgLocation = ({ }) => (
  <section className="site-section section-map">
    <div className="inner">
      <div className="location">
        <img id="map-marker" src="images/map-marker.png" className="hide" />
        <div id="map" className="map" data-lat="-34.557107639814106" data-lng="-58.44733878510846" data-title="PBG Desarrollos"></div>
      </div>
      <div className="box">

        <h1 className="title">PBG</h1>
        <address>Av. Libertador 5936 12°B<br />Ciudad de Buenos Aires, </address>

        <a href="https://www.google.com/maps/place/Av.+del+Libertador+5936,+C1428+Buenos+Aires/@-34.5582828,-58.4519951,17.3z/data=!4m6!3m5!1s0x95bcb5ccb8046667:0x744fec15fef242ed!8m2!3d-34.5572861!4d-58.4474194!16s%2Fg%2F11g8yhvgpc?entry=ttu" className="button" target="_blank">Abrir en google maps</a>
      </div>
    </div>
  </section>
)

const InvestInPbg = ({ }) => (
  <section className="site-section section-investment secondary">
    <div className="row">
      <div className="grid-x grid-padding-x align-center">
        <div className="small-11 medium-8 large-6 cell">
          <span className="title-subtext animated fadeInUp appear-animation" data-animation="fadeInUp">Invertí con PBG</span>
          <h1 className="title section-title invert is-underline">La solidez siempre es<br />un buen negocio</h1>
          <div className="section-description">
            <p>Invertí en cualquiera de nuestros proyectos de Real Estate y accedé de forma simple y transparente a las ganancias que genera el sector inmobiliario. En PBG hacemos foco en la rentabilidad final de todos nuestros negocios inmobiliarios para garantizarle a nuestros inversores el resguardo de su capital y los mejores rendimientos sobre su inversión.</p>
            <a href="#" className="button animated fadeInUp  appear-animation" onClick={() => GlobalVars.openSiteContact()} data-animation="fadeInUp" >Contáctanos</a>
          </div>
        </div>
      </div>

      <div className="items">
        <div className="grid-x grid-padding-x align-center">

          <div className="small-12 medium-3 cell item">
            <h2 className="title">Base rentable</h2>
            <p>Buscamos y seleccionamos terrenos con ubicaciones Premium, que garanticen una alta rentabilidad.</p>
          </div>

          <div className="small-12 medium-3 cell item">
            <h2 className="title">Confianza</h2>
            <p>Nuestra gestión profesional y trato personal hace que nuestros inversores nos elijan para toda la vida.</p>
          </div>

          <div className="small-12 medium-3 cell item">
            <h2 className="title">Grandes relaciones</h2>
            <p>Nos asociamos con los mejores profesionales del rubro inmobiliario y constructivo.</p>
          </div>

          <div className="small-12 medium-3 cell item">
            <h2 className="title">Calidad</h2>
            <p>Cuidamos cada detalle, desde la construcción general hasta la terminación final en las unidades.</p>
          </div>

          <div className="small-12 medium-3 cell item">
            <h2 className="title">Dinamismo</h2>
            <p>Somos ágiles, flexibles y modernos, ese es el secreto de nuestro crecimiento.</p>
          </div>

          <div className="small-12 medium-3 cell item">
            <h2 className="title">Servicio 360°</h2>
            <p>Gestión integral: Selección del terreno, diseño, desarrollo, comercialización y post venta.</p>
          </div>

          <div className="small-12 medium-3 cell item">
            <h2 className="title">Respaldo</h2>
            <p>Tenemos solidez financiera que garantiza la fluidez de cada obra.</p>
          </div>

          <div className="small-12 medium-3 cell item">
            <h2 className="title">Asesoría</h2>
            <p>Acompañamos a nuestros inversores durante todo el proceso para que obtengan máximos beneficios.</p>
          </div>

        </div>
      </div>
    </div>
  </section>
)

export default Home;
export { AboutPbg, GalleryPreFooter, ImproveRental, InvestInPbg, PbgLocation };
