import React from 'react';
import { withRouter } from "react-router-dom";
import '../Styling/accountStyles.css';
import OliverHelper, { GlobalVars } from '../../utils/Oliverhelper';
import moment from 'moment';
import 'moment/locale/es';
import AuthService from '../../utils/AuthService';
const auth = new AuthService();


class MiCuentaNuevasInversiones extends React.Component {
  constructor(props) {
    super(props);
    this.state = { k: [], dataProject: [], showModalSuccess: false };
  }


  componentDidMount() {
    let logged = auth.loggedIn();
    if (!logged || logged == 'undefined') {
      this.props.history.push('/mis-unidades-pbg');
      return;
    }


    document.title = 'Suma una nueva inversión con nosotros';
    OliverHelper.ClientAPI.getProjects({ nueva_inversion: 1 }).then(response => {
      this.setState({ dataProject: response.emprendimientos });
    });
  }

  askForProject = (emprendimiento, index) => {
    let k = [];
    k[index] = 1;
    this.setState({ k });
    OliverHelper.ClientAPI.askForProject({ emprendimiento_id: emprendimiento.id }).then(response => {
    })
  }

  //goTo = (project) => this.props.history.push('/proyecto/' + project.emprendimiento.id);

  getImageByType = (type, digitales) => {
    let digital = false;
    digitales.map(eachDigital => {
      //console.log(eachDigital.tipo.toLowerCase(), '  con ', type.toLowerCase());
      if (eachDigital.tipo.toLowerCase() == type.toLowerCase()) {
        digital = eachDigital;
        return;
      }
    });
    return digital;
  }
  render() {
    console.log('k', this.state.k);
    return (
      <div>
        <section className="interface-section first full-height dark">
          <div className="inner">
            <header className="title-wrapper">
              <h1 className="title">Suma una <span className="hide-for-small-only">nueva</span> inversión</h1>
              <span className="icon icon-house-plus"></span>
            </header>

            <div className="grid-x units layout-new-investment">
              {this.state.dataProject.map((unidad, i) => {
                let backImage = this.getImageByType('emprendimiento_slider', unidad.digitales);

                return <div className="small-12 medium-6 large-4 cell item">
                  <div className="image">
                    {unidad.emprendimiento.descuento_usuario && <span className="discount">{unidad.emprendimiento.descuento_usuario}</span>}
                    <img src={`${backImage.image_path}`} />
                  </div>
                  <div className="content">
                    <h2>{unidad.emprendimiento.nombre}</h2>
                    <a href={`proyecto/${unidad.emprendimiento.id}`} target="_blank">Ver detalles</a>
                    <p className="date-location">{GlobalVars.showDate(unidad.emprendimiento.fecha_entrega, true)} / {unidad.emprendimiento.domicilio.barrio}</p>
                    <button type='button'
                      onClick={() => this.askForProject(unidad.emprendimiento, i)}
                      className={`button consultation ${this.state.k != undefined && this.state.k[i] != undefined ? 'complete' : ''} `}
                    >
                      {this.state.k != undefined && this.state.k[i] != undefined ? 'Nos estaremos comunicando con vos' : 'Consultar por unidades y precios'}
                    </button>
                  </div>
                </div>


              }
              )}
            </div>
          </div>
        </section>
        <section className={`password-change ${this.state.showModalSuccess ? 'visible' : ''}`}>
          <div className="inner">
            <h1 className="title">Gracias por su consulta</h1>

            <div onClick={() => this.setState({ showModalSuccess: false })} className="close"><span></span></div>
          </div>
        </section>
      </div>
    );
  }
}




export default withRouter(MiCuentaNuevasInversiones);
