import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { InvestInPbg, PbgLocation, GalleryPreFooter } from "./Home";
import { GmapsView, AlertWeb } from "../Tools/Utils";
import OliverHelper, { GlobalVars } from "../../utils/Oliverhelper";
import swal from "sweetalert";

//icons
import estado from "../Styling/images/home/icons_estado.png";
import apto from "../Styling/images/home/icons_aptoprof.png";
import fecha from "../Styling/images/home/icons_fecha.png";
import tipologia from "../Styling/images/home/icons_tipologia.png";
import supericie from "../Styling/images/home/icons_terraza verde.png";

const google = window.google;

class Proyecto extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataProject: [],
      unidades: [],
      planoToSee: null,
      amenitiesProject: [],
      bannersFooter: [],
      domicilioProject: [],
      tipoSelected: {},
      tabActive: false,
      uniSelected: {},
      project: {},
      contact: { nombre: "", email: "", telefono: "", mensaje: "" },
      digitalProject: [],
    };
    this.initMap = this.initMap.bind(this);
  }

  componentDidMount() {
    let id = this.props.match.params.id;
    OliverHelper.ClientAPI.getProject({ id: id }).then((response) => {
      document.title = response.emprendimiento.nombre + " - PBG Desarrollos";
      this.setState(
        {
          amenitiesProject: response.amenities,
          dataProject: response.emprendimiento,
          unidades: response.unidades,
          domicilioProject: response?.domicilio[0],
          digitalProject: response.digitales,
          project: response,
          tipoSelected:
            response.tipologias[0] != undefined ? response.tipologias[0] : {},
        },
        () => {
          window.loadSliderBarrio();
          // this.initMap(this.state?.domicilioProject?.latitude, this.state?.domicilioProject?.longitude);
          this.initMap();
        }
      );
    });
    OliverHelper.ClientAPI.getProjects({ home_top_slider: 1 }).then(
      (response) => {
        this.setState({ bannersFooter: response.bannersFooter });
      }
    );
    // Asignar initMap al objeto window
    window.initMap = this.initMap;
  }

  async loadGoogleMaps() {
    if (typeof google !== 'undefined' && google.maps) {
      this.initMap();
    } else {
      console.log("Google Maps API is not loaded yet. Retrying...");
      setTimeout(this.loadGoogleMaps.bind(this), 1000); // Reintentar cada 1 segundo
    }
  }


  
  async initMap() {
    const lat = this.state?.domicilioProject?.latitude;
    const lng = this.state?.domicilioProject?.longitude;

    if (!lat || !lng) {
      console.error(
        "Missing latitude or longitude data for map"
      );
      return; // Abort map creation if data is missing
    } 

    var s = [
      {
        featureType: "all",
        elementType: "geometry.fill",
        stylers: [{ weight: "2.00" }],
      },
      {
        featureType: "all",
        elementType: "geometry.stroke",
        stylers: [{ color: "#9c9c9c" }],
      },
      {
        featureType: "all",
        elementType: "labels.text",
        stylers: [{ visibility: "on" }],
      },
      {
        featureType: "administrative.land_parcel",
        elementType: "geometry.fill",
        stylers: [
          { visibility: "on" },
          { color: "#372305" },
          { saturation: "-25" },
        ],
      },
      {
        featureType: "landscape",
        elementType: "all",
        stylers: [{ color: "#f2f2f2" }],
      },
      {
        featureType: "landscape",
        elementType: "geometry.fill",
        stylers: [{ color: "#ffffff" }],
      },
      {
        featureType: "landscape.man_made",
        elementType: "geometry.fill",
        stylers: [{ color: "#ffffff" }],
      },
      {
        featureType: "poi",
        elementType: "all",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "road",
        elementType: "all",
        stylers: [{ saturation: -100 }, { lightness: 45 }],
      },
      {
        featureType: "road",
        elementType: "geometry.fill",
        stylers: [{ color: "#eeeeee" }],
      },
      {
        featureType: "road",
        elementType: "labels.text.fill",
        stylers: [{ color: "#7b7b7b" }],
      },
      {
        featureType: "road",
        elementType: "labels.text.stroke",
        stylers: [{ color: "#ffffff" }],
      },
      {
        featureType: "road.highway",
        elementType: "all",
        stylers: [{ visibility: "simplified" }],
      },
      {
        featureType: "road.arterial",
        elementType: "labels.icon",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "transit",
        elementType: "all",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "water",
        elementType: "all",
        stylers: [{ color: "#46bcec" }, { visibility: "on" }],
      },
      {
        featureType: "water",
        elementType: "geometry.fill",
        stylers: [{ color: "#c8d7d4" }],
      },
      {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [{ color: "#070707" }],
      },
      {
        featureType: "water",
        elementType: "labels.text.stroke",
        stylers: [{ color: "#ffffff" }],
      },
    ];
    var c = 28;
    var d = parseInt((c * c) / 22);

    if (typeof google === 'undefined' || !google.maps) {
      console.error("Google Maps API is not loaded.");
      return; // Abort if Google Maps API is not loaded
    }

    var p = {
      anchor: new google.maps.Point(c / 2, d / 2),
      origin: new google.maps.Point(0, 0),
      scaledSize: new google.maps.Size(c, d),
      url: `/images/map-marker.png`,
    };

    let point = { lat: parseFloat(lat), lng: parseFloat(lng) };
    console.log("point", point);

    var map = new google.maps.Map(document.getElementById("map"), {
      center: point,
      zoom: 14,
      styles: s,
    });
    new google.maps.Marker({ position: point, map: map, icon: p });
  }

  getDigitalesByType = () => {
    //<---- muestra digital tipo "brochure"
    var brochureToLink = this.getType("Brochure");
    console.log("Brochure", brochureToLink);
    if (!brochureToLink.image_path) return null;
    return (
      <button
        className="button bottom"
        onClick={() => window.open(brochureToLink.image_path)}
      >
        Ver Brochure
      </button>
    );
  };

  getDigitalesByTipologia = () => {
    //<---- muestra digital tipo "tipologia"
    var tipologiaToLink = this.getType("Tipologia");
    console.log("Tipologia", tipologiaToLink);
    return (
      <div>
        <img src={tipologiaToLink.image_path} />
      </div>
    );
  };

  getDigitalesByUnidadDestacada = () => {
    //<---- muestra digital tipo "unidad destacada"
    var unidadDestacada = this.getType("emprendimiento_detalle_destacada");
    //console.log('unidad_destacada', unidadDestacada);
    return (
      <div>
        <img src={unidadDestacada.image_path} />
      </div>
    );
  };

  getType = (typeToSearch) => {
    var digitales = this.state.digitalProject;
    //console.log('digitales', digitales)
    var digitalWanted = {};
    digitales.map((eachDigital) => {
      //console.log('PARA MAPEAR', eachDigital.tipo, typeToSearch)
      if (eachDigital.tipo === typeToSearch) {
        digitalWanted = eachDigital;
      }
    });
    return digitalWanted;
  };

  getImageByType = (type, digitales, returnArray = false) => {
    let digital = false;
    let array = [];
    digitales.map((eachDigital) => {
      //console.log(eachDigital.tipo.toLowerCase(), '  con ', type.toLowerCase());
      if (eachDigital.tipo.toLowerCase() == type.toLowerCase()) {
        digital = eachDigital;
        array.push(eachDigital);
        return;
      }
    });
    return returnArray ? array : digital;
  };

  parseTipologias = (unidades) => {
    let tipologias = [];
    unidades.map((eachUnidad) => tipologias.push(eachUnidad.tipologia));
    return tipologias;
  };

  setTipology = (tipology, valueDirectly = false) => {
    if (tipology.target != undefined && tipology.target.value.length > 60) {
      let tipoToJson = JSON.parse(tipology.target.value);
      this.setPlanta(tipoToJson);
    } else {
      this.setState({
        plantaSelected: {},
        tipoSelected: valueDirectly ? tipology : tipology.target.value,
      });
    }
  };

  setUnidad = (unidad) => {
    //console.log('uni selected', unidad);
    this.setState({ uniSelected: unidad });
  };

  parseUnidadesByTipo = (unidades, tipologias) => {
    var unidadesArray = [];
    tipologias.map((eachTipo, i_tipo) => {
      let tipoArray = { tipo: eachTipo, unidades: [] };
      unidades.map((eachUnidad) => {
        if (eachUnidad.tipologia == eachTipo) {
          tipoArray.unidades.push(eachUnidad);
        }
      });
      unidadesArray.push(tipoArray);
    });
    //console.log(unidadesArray, 'tipo array foreedmed')
    return unidadesArray;
  };

  parseUnidadesByTipoAgrupadas = (unidades, tipologias, unidadesAgrupadas) => {
    console.log("unidades agrupadas", unidadesAgrupadas);
    var unidadesArray = [];
    tipologias.map((eachTipo, i_tipo) => {
      let tipoArray = { tipo: eachTipo, unidades: [] };
      unidadesAgrupadas.map((eachUnidadArray) => {
        eachUnidadArray.map((eachUnidad) => {
          if (eachUnidad.tipologia == eachTipo) {
            tipoArray.unidades.push(eachUnidad);
          }
        });
      });
      unidadesArray.push(tipoArray);
    });
    //console.log(unidadesArray, 'tipo array foreedmed')
    return unidadesArray;
  };

  sendContact = () => {
    if (
      !this.state.contact.nombre.length ||
      !this.state.contact.email.length ||
      !this.state.contact.telefono.length ||
      !this.state.contact.mensaje
    ) {
      swal(
        "Atención!",
        "Debe completar todos los campos del formulario",
        "warning"
      );
      return false;
    }
    if (!OliverHelper.validateEmail(this.state.contact.email)) {
      swal("Atención!", "Debe completar con un email válido", "warning");
      return false;
    }
    this.setState({ sending: true });
    OliverHelper.ClientAPI.sendContact(this.state.contact).then((response) => {
      //console.log('llega', response);
      this.setState({ sent: true, sending: false });
    });
  };

  setField = (name, val) => {
    let contact = this.state.contact;
    contact[name] = val;
    this.setState({ contact: contact });
    //console.log('name', name, val);
  };

  setPlanoToSee = (val) => this.setState({ planoToSee: val });

  goTo = (project) => this.props.history.push("/proyecto/" + project.id);

  setPlanta = (data) =>
    this.setState({ plantaSelected: data, tipoSelected: {}, uniSelected: {} });

  setTabActive = (val) => this.setState({ tabActive: val });

  render() {
    let tipologias =
      this.state.project.tipologias != undefined
        ? this.state.project.tipologias
        : [];
    var brochureToLink = this.getType("Brochure");

    return (
      <div>
        {/**
        <svg className='leftArrow' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"><path d="M143.5 221.9L336.2 29.1c6.7-6.7 6.7-17.5 0-24.1 -6.7-6.7-17.5-6.7-24.1 0l-204.8 204.8c-6.7 6.7-6.7 17.5 0 24.1l204.8 204.8c6.8 6.5 17.6 6.4 24.1-0.4 6.4-6.6 6.4-17.1 0-23.7L143.5 221.9z" /></svg>
        <svg className='rightArrow' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"><path d="M388.4 242L151.6 5.8c-7.8-7.7-20.3-7.7-28.1 0 -7.7 7.8-7.7 20.3 0 28.1l222.7 222.1L123.6 478.1c-7.8 7.7-7.8 20.3 0 28.1 3.9 3.9 9 5.8 14.1 5.8 5.1 0 10.1-1.9 14-5.8l236.8-236.2c3.7-3.7 5.8-8.8 5.8-14S392.2 245.7 388.4 242z" /></svg>
        */}

        <TopBanner
          getImageByType={this.getImageByType}
          dataProject={this.state.dataProject}
          domicilioProject={this.state.domicilioProject}
          digitalProject={this.state.digitalProject}
          getDigitalesByType={this.getDigitalesByType()}
        />
        <ProjectStatus
          tipologias={tipologias}
          project={this.state.project}
          dataProject={this.state.dataProject}
          projectAmenities={this.state.amenitiesProject}
        />
        <ProjectBanner
          dataProject={this.state.dataProject}
          getDigitalesByTipologia={this.getDigitalesByTipologia()}
        />
        <ProjectContact
          sending={this.state.sending}
          sent={this.state.sent}
          contact={this.state.contact}
          sendContact={this.sendContact}
          setField={this.setField}
        />

        {tipologias.length && (
          <ProjectStructure
            tipologias={tipologias}
            setTabActive={this.setTabActive}
            tabActive={this.state.tabActive}
            project={this.state.project}
            digitalProject={this.state.digitalProject}
            getDigitalesByUnidadDestacada={this.getDigitalesByUnidadDestacada()}
          />
        )}
        {this.state.amenitiesProject.length && (
          <ProjectAmenities
            brochureToLink={brochureToLink}
            projectAmenities={this.state.amenitiesProject}
          />
        )}
        {this.state.unidades.length && (
          <ProjectFloorPlans
            setPlanta={this.setPlanta}
            plantaSelected={this.state.plantaSelected}
            planoToSee={this.state.planoToSee}
            setPlanToSee={this.setPlanoToSee}
            project={this.state.project}
            setTipology={this.setTipology}
            parseUnidadesByTipo={this.parseUnidadesByTipo}
            parseUnidadesByTipoAgrupadas={this.parseUnidadesByTipoAgrupadas}
            tipoSelected={this.state.tipoSelected}
            uniSelected={this.state.uniSelected}
            setUnidad={this.setUnidad}
            parseTipologias={this.parseTipologias}
            unidades={this.state.unidades}
          />
        )}
        <ProjectNeighborhood
          getImageByType={this.getImageByType}
          digitalProject={this.state.digitalProject}
          dataProject={this.state.dataProject}
        />
        <GalleryPreFooter data={this.state.bannersFooter} />
      </div>
    );
  }
}

const ProjectNeighborhood = ({
  dataProject,
  digitalProject,
  getImageByType,
}) => {
  console.log("domiclio", dataProject);
  let barrio = GlobalVars.BARRIOS[0];
  let latitude = false;
  let longitude = false;
  let domicilio = false;
  let barrioBack = `/images/project-detail/neighborhood-background.jpg`;
  if (
    dataProject.domicilio != undefined &&
    dataProject.domicilio.latitude != undefined
  ) {
    domicilio =
      dataProject.domicilio.calle +
      " " +
      dataProject.domicilio.numero +
      ", " +
      dataProject.domicilio.barrio;
    latitude = dataProject.domicilio.latitude;
    longitude = dataProject.domicilio.longitude;
  }
  let backBarrio = getImageByType("barrio_background", digitalProject);
  let sliderBarrio = getImageByType("barrio", digitalProject, true);

  return (
    <div>
      <section className="site-section project-neighborhood">
        <div
          className="title-wrapper cover text-center"
          style={{ backgroundImage: `url(${backBarrio.image_path})` }} //<--------- HARCODEADO
        >
          <h1 className="title section-title">Explorá el barrio</h1>
          <p className="animated fadeInUp  " data-animation="fadeInUp">
            {dataProject.titulo_barrio}
          </p>
        </div>
        <div className="content   " data-animation="fadeInUp">
          <div className="images">
            {sliderBarrio.map((eachImage, i) => (
              <div
                key={i}
                className="item cover"
                style={{ backgroundImage: `url(${eachImage.image_path})` }} //<--------- HARCODEADO
              >
                <img src={`${eachImage.image_path}`} />
              </div>
            ))}
          </div>
          <div className="text">
            <h2 className="title invert is-underline">
              {dataProject.subtitulo_barrio}
            </h2>
            <p className="lead">{dataProject.titulo3_barrio}</p>
            <p>{dataProject.descripcion_barrio}</p>
          </div>
        </div>
      </section>
      <section className="site-section section-map layout-project">
        <div className="inner">
          <div className="location">
            <img
              id="map-marker"
              src="/images/map-marker.png"
              className="hide"
            />
            <GmapsView data-lat={latitude} data-lng={longitude} />
          </div>
          <div className="box">
            <h1 className="title">{dataProject.nombre}</h1>
            <address>{domicilio}</address>

            <a
              href={`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}&zoom=20`}
              className="button"
              target="_blank"
            >
              Abrir en google maps
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

const TopBanner = ({
  dataProject,
  getImageByType,
  domicilioProject,
  digitalProject,
  getDigitalesByType,
}) => {
  let backImage = getImageByType("background", digitalProject);
  let digital = backImage.image_path;
  console.log("bacground image", backImage);

  return (
    <div className="top-content is-single layout-project">
      <div
        className="image cover"
        style={{ backgroundImage: `url(${digital})` }}
      />
      <div className="text">
        <h2 className="title">
          {dataProject.nombre ? dataProject.nombre : ""}
        </h2>
        <p>
          {domicilioProject.calle
            ? domicilioProject.calle +
              " " +
              domicilioProject.numero +
              ", " +
              domicilioProject.barrio +
              " " +
              domicilioProject.pais
            : ""}
        </p>
        {getDigitalesByType}
      </div>
      <div className="social show-for-large">
        <a href={GlobalVars.LINK_IG} target="_blank">
          <img src="/images/icon-instagram-white.png" />
        </a>
        <a href={GlobalVars.LINK_FB} target="_blank">
          <img src="/images/icon-facebook-white.png" />
        </a>
      </div>
    </div>
  );
};

const ProjectFloorPlans = ({
  project,
  parseUnidadesByTipo,
  parseUnidadesByTipoAgrupadas,
  setTipology,
  setPlanta,
  plantaSelected,
  setUnidad,
  tipoSelected,
  setPlanToSee,
  planoToSee,
  uniSelected,
  unidades,
}) => {
  let unidadesByTipo = parseUnidadesByTipo(unidades, project.tipologias);
  unidadesByTipo = parseUnidadesByTipoAgrupadas(
    unidades,
    project.tipologias,
    project.unidadesAgrupadas
  );
  console.log(
    "unidades by tipo",
    unidadesByTipo,
    "tipo selected",
    tipoSelected,
    "planta selected",
    plantaSelected
  );

  return (
    <section
      className={`site-section light project-floorplans ${
        planoToSee ? "floorplan-open" : ""
      }`}
    >
      <div className="row">
        <h1 className="title section-title text-center is-underline">
          Plantas
        </h1>

        <div className="floorplans">
          <div
            className={`typologies ${
              tipoSelected.length ||
              (plantaSelected && plantaSelected.id != undefined)
                ? " typology-selected"
                : ""
            }`}
          >
            <select
              className="typology-select hide-for-large"
              onChange={(e) => {
                setTipology(e);
              }}
            >
              <option value="0">Tipologías</option>
              {project.tipologias.map((i) => {
                return (
                  i.toLowerCase() != "cochera" &&
                  i.toLowerCase() != "baulera" && (
                    <option key={i} value={i}>
                      {i}
                    </option>
                  )
                );
              })}
              {project.digitales.map((eachDigital, i_plantas) => {
                if (eachDigital.tipo.toLowerCase() == "plantas_comunes") {
                  return (
                    <option key={i_plantas} value={JSON.stringify(eachDigital)}>
                      {eachDigital.nombre
                        ? eachDigital.nombre
                        : "sin nombre planta"}
                    </option>
                  );
                }
              })}
            </select>
            <ul className="list show-for-large " data-animation="fadeInUp">
              {project.tipologias.map((i) => {
                return (
                  i.toLowerCase() != "cochera" &&
                  i.toLowerCase() != "baulera" && (
                    <li
                      className={i == tipoSelected ? "active" : ""}
                      onClick={(e) => setTipology(i, true)}
                      key={i}
                      value={i}
                    >
                      <span>{i}</span>
                    </li>
                  )
                );
              })}
              {project.digitales.map((eachDigital, i_plantas) => {
                if (eachDigital.tipo.toLowerCase() == "plantas_comunes") {
                  return (
                    <li
                      key={i_plantas}
                      className={plantaSelected == eachDigital ? "active" : ""}
                      onClick={(e) => setPlanta(eachDigital, true)}
                      value={i_plantas}
                    >
                      <span>
                        {eachDigital.nombre
                          ? eachDigital.nombre
                          : "sin nombre planta"}
                      </span>
                    </li>
                  );
                }
              })}
            </ul>
          </div>

          {plantaSelected && plantaSelected.id != undefined ? (
            <div>
              <div
                className={`show-for-large`}
                style={{ width: "70%", float: "left", marginLeft: 20 }}
              >
                <div>
                  <img src={plantaSelected.image_path} />
                  <div className="text-center">
                    <span
                      style={{
                        background: "#2accf1",
                        borderColor: "#2accf1",
                        color: "#fff",
                        marginTop: 10,
                      }}
                      onClick={() => setPlanToSee(plantaSelected.image_path)}
                      className="button view-floorplan"
                    >
                      Agrandar plano
                    </span>
                  </div>
                </div>
              </div>
              <div className={`hide-for-large`} style={{}}>
                <div>
                  <img src={plantaSelected.image_path} />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="typology-units">
            {unidadesByTipo.map((eachTipo, i) => {
              return (
                <div
                  key={i}
                  className={
                    eachTipo.tipo == tipoSelected ? `units active` : `units`
                  }
                  data-typology={eachTipo.tipo}
                >
                  {eachTipo.unidades.map((eachUni) => {
                    return (
                      <div
                        className="item"
                        data-value={eachUni.unidad}
                        onClick={() => setUnidad(eachUni)}
                      >
                        <span className="number">{eachUni.descripcion}</span>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>

          {uniSelected.unidad != undefined && (
            <div className="unit-detail">
              <div className="item active" data-unit="unit-02">
                <p className="unit-floor">{uniSelected.piso}</p>
                <p className="unit-number">
                  {uniSelected.descripcion} (
                  <span className="text">{uniSelected.disposicion}</span>)
                </p>
                <ul>
                  <li>
                    Sup. Cubierta{" "}
                    <span className="value">
                      {uniSelected.mts_cubiertos} m2
                    </span>
                  </li>
                  <li>
                    Sup. No Cubierta{" "}
                    <span className="value">
                      {uniSelected.mts_nocubiertos} m2
                    </span>
                  </li>
                  <li>
                    Sup. Amenities{" "}
                    <span className="value">
                      {uniSelected.superficie_amenities} m2
                    </span>
                  </li>
                  <li>
                    Sup. Total{" "}
                    <span className="value">
                      {(
                        parseFloat(uniSelected.mts_cubiertos) +
                        parseFloat(uniSelected.mts_nocubiertos) +
                        parseFloat(uniSelected.superficie_amenities)
                      ).toFixed(2)}{" "}
                      m2
                    </span>
                  </li>
                </ul>
                {uniSelected.plano && (
                  <div className="text-center">
                    <span
                      onClick={() => setPlanToSee(uniSelected.plano.image_path)}
                      className="button view-floorplan"
                    >
                      Ver plano
                    </span>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="floorplan-image">
        <div onClick={() => setPlanToSee(null)} className="floorplan-close">
          <span></span>
        </div>
        {planoToSee && <img src={planoToSee} />}
      </div>
    </section>
  );
};

const ProjectStatus = ({
  project,
  dataProject,
  tipologias,
  projectAmenities,
}) => {
  return (
    <section className="site-section project-introduction">
      <div className="row">
        <div className="grid-x align-center">
          <div className="small-12 medium-8 large-6 cell text">
            <h1 className="title section-title is-underline">
              {dataProject.titulo_inicial}
            </h1>
            <div className="section-description">
              <p>{dataProject.descripcion_inicial}</p>
            </div>
          </div>
        </div>

        <div className="list">
          <div className="grid-x small-up-1 medium-up-5">
            <div
              className="cell item animated fadeIn"
              data-animation="fadeIn"
              data-animation-delay="300"
            >
              <img src={estado} />
              <h2 className="title is-underline">Estado</h2>
              <p>{dataProject.estado_emprendimiento}</p>
            </div>

            <div
              className="cell item animated fadeIn"
              data-animation="fadeIn"
              data-animation-delay="900"
            >
              <img src={apto} />
              <h2 className="title is-underline">Apto profesional</h2>
              <p>{dataProject.apto_profesional ? "Si" : "No"}</p>
            </div>

            <div
              className="cell item animated fadeIn"
              data-animation="fadeIn"
              data-animation-delay="600"
            >
              <img src={fecha} />
              <h2 className="title is-underline">Fecha de entrega</h2>
              <p>{GlobalVars.showDate(dataProject.fecha_entrega, true)}</p>
            </div>

            {tipologias.length ? (
              <div
                className="cell item animated fadeIn"
                data-animation="fadeIn"
                data-animation-delay="1200"
              >
                <img src={tipologia} />
                <h2 className="title is-underline">Tipologias</h2>
                <p>
                  {tipologias.map((eachTipo, i) => (
                    <li key={i}>{eachTipo}</li>
                  ))}
                </p>
              </div>
            ) : (
              ""
            )}
            {project.unidadMenorMts && project.unidadMayorMts ? (
              <div
                className="cell item animated fadeIn"
                data-animation="fadeIn"
                data-animation-delay="1500"
              >
                <img src={supericie} />
                <h2 className="title is-underline">Superficie</h2>
                <p>{`Desde ${project.unidadMenorMts} / Hasta ${project.unidadMayorMts} m²`}</p>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

const ProjectBanner = ({ dataProject, getDigitalesByTipologia }) => (
  <section className="site-section project-extended-information">
    <div className="row">
      <div className="grid-x grid-padding-x align-center">
        <div className="small-12 medium-8 large-6 cell text-center">
          <h1 className="title section-title invert is-underline">
            {dataProject.titulo_tipologia}
          </h1>
          <div className="section-description">
            <p>{dataProject.descripcion_tipologia}</p>
          </div>
        </div>
        <div className="small-12 large-8 cell">{getDigitalesByTipologia}</div>
      </div>
    </div>
  </section>
);

const ProjectContact = ({ sendContact, contact, setField, sending, sent }) => (
  <section className="site-section primary project-contact">
    <div className="row">
      <div className="grid-x grid-padding-x align-center">
        <div className="small-12 medium-8 large-6 cell">
          <div className="text-center">
            <h1 className="title section-title is-underline">
              Ponete en contacto <br />
              con nosotros
            </h1>
            <div className="section-description">
              <p>
                Completa el siguiente formulario y te responderemos a la
                brevedad.
              </p>
            </div>
          </div>
          <div className="form-wrapper">
            <form action="#">
              <div className="input-wrapper">
                <label htmlFor="project-name" className="hide">
                  Nombre
                </label>
                <input
                  type="text"
                  id="project-name"
                  onChange={(data) => setField("nombre", data.target.value)}
                  value={contact.nombre}
                  name="nombre"
                  placeholder="Nombre_"
                  required
                />
              </div>
              <div className="input-wrapper">
                <label htmlFor="project-email" className="hide">
                  Email
                </label>
                <input
                  type="text"
                  id="project-email"
                  onChange={(data) => setField("email", data.target.value)}
                  value={contact.email}
                  name="email"
                  placeholder="Email_"
                  required
                />
              </div>
              <div className="input-wrapper">
                <label htmlFor="project-phone" className="hide">
                  Teléfono
                </label>
                <input
                  type="text"
                  id="project-phone"
                  onChange={(data) => setField("telefono", data.target.value)}
                  value={contact.telefono}
                  name="telefono"
                  placeholder="Tel_"
                  required
                />
              </div>
              <div className="input-wrapper type-textarea">
                <label htmlFor="project-message" className="hide">
                  Mensaje
                </label>
                <textarea
                  id="project-message"
                  onChange={(data) => setField("mensaje", data.target.value)}
                  name="mensaje"
                  required
                  placeholder="Mensaje_"
                  cols="40"
                  rows="4"
                >
                  {contact.mensaje}
                </textarea>
              </div>
              <div className="input-wrapper type-submit text-center">
                {sent ? (
                  <AlertWeb />
                ) : (
                  <input
                    type="button"
                    onClick={() => sendContact()}
                    value={sending !== true ? "Enviar" : "Enviando..."}
                    className="button"
                  />
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const ProjectStructure = ({
  digitalProject,
  project,
  setTabActive,
  tabActive = false,
  tipologias,
}) => {
  const [recoVisible, setRecoVisible] = useState(0);
  const tabActiveConstant = { RECORRIDO: "recorrido", GALERIA: "galeria" };
  let recorridos = project.recorridos;
  //let recorridos = [];
  let tieneRecorrido = recorridos.length;
  tabActive = !tabActive
    ? tieneRecorrido
      ? tabActiveConstant.RECORRIDO
      : tabActiveConstant.GALERIA
    : tabActive;
  let displayGallery =
    tabActive == tabActiveConstant.GALERIA ? "initial" : "hidden";
  let displayRecorridos =
    tabActive == tabActiveConstant.RECORRIDO ? "block" : "none";

  console.log("reco visible", recoVisible);
  return (
    <div>
      <section className="site-section light project-units">
        <div className="row">
          <div className="grid-x grid-padding-x">
            {/*  2 DOS IMAGENES DE UNIDAD, DENTRO DEL EMPRENDIMEINTO OSEA => digitales de emprendimiento tipo "unidad_destacada"  */}
            {digitalProject.map(
              (eachDigital, i) =>
                eachDigital.tipo == "emprendimiento_detalle_destacada" && (
                  <div
                    key={i}
                    className="small-12 medium-6 cell unit-image animated fadeInUp"
                    data-animation="fadeInUp"
                  >
                    <div>
                      <img src={eachDigital.image_path} />
                    </div>
                  </div>
                )
            )}

            <div
              className="small-12 large-6 cell text animated fadeInUp"
              data-animation="fadeInUp"
            >
              <h2 className="title is-underline">
                {project.emprendimiento.titulo_comercial_tipologia}
              </h2>
              <ul>
                <li>{project.emprendimiento.txt_comercial_tipologia}</li>
                {/**{tipologias.map(eachTipo => <li>{eachTipo}</li>)} */}
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div
        className="project-gallery"
        style={{
          backgroundColor: "black",
          paddingTop: 30,
          height: window.screen.height - 80,
        }}
      >
        {tieneRecorrido ? (
          <div class="digitalOption">
            <button
              onClick={() => setTabActive(tabActiveConstant.RECORRIDO)}
              class={tabActive == tabActiveConstant.RECORRIDO ? "active" : ""}
              type="button"
            >
              Recorrido 360
            </button>

            <button
              onClick={() => setTabActive(tabActiveConstant.GALERIA)}
              class={tabActive == tabActiveConstant.GALERIA ? "active" : ""}
              type="button"
            >
              Galería de fotos
            </button>
          </div>
        ) : null}

        {tieneRecorrido && tabActive == tabActiveConstant.RECORRIDO ? (
          <div style={{ margin: 20 }}>
            <select
              style={{ maxWidth: 300 }}
              className="typology-selectw"
              onChange={(e) => setRecoVisible(e.target.value)}
            >
              {recorridos.map((recorrido, o) => (
                <option value={o}>{recorrido.descripcion}</option>
              ))}
            </select>
          </div>
        ) : null}

        <div
          className="recorridos"
          style={{ height: "100%", display: displayRecorridos }}
        >
          {recorridos.map((recorrido, o) => (
            <div
              id={`reco-${o}`}
              className={"item"}
              style={{ display: o == recoVisible ? "block" : "none" }}
            >
              {/**<h5 style={{ marginBottom: '20px' }}>{recorrido.descripcion}</h5> */}
              <iframe
                style={{ border: "none" }}
                height={"530px"}
                width={"100%"}
                src={recorrido.url}
              />
            </div>
          ))}
        </div>

        <div
          className="images"
          style={{ height: "100%", visibility: displayGallery }}
        >
          {project.digitales.map(
            (eachDigital, o) =>
              eachDigital.tipo == "Render" && (
                <div key={o} className="item">
                  <img src={eachDigital.image_path} />
                </div>
              )
          )}
        </div>
      </div>
    </div>
  );
};

const ProjectAmenities = ({ projectAmenities, brochureToLink }) => (
  <section className="site-section secondary project-amenities">
    <div className="row">
      <h1 className="title section-title invert is-underline text-center">
        Amenities <br className="show-for-large" />y servicios generales
      </h1>

      <div className="list">
        <div className="grid-x">
          {projectAmenities.map((eachAmenitie, i) => (
            <EachAmenitie key={i} amenitiesProject={eachAmenitie} />
          ))}
        </div>
      </div>
      {brochureToLink.image_path != undefined && (
        <div className="text-center">
          <a
            href={brochureToLink.image_path}
            className="button appear-animation animated fadeInUp"
            target="_blank"
            data-animation="fadeInUp"
          >
            <em>Ver Brochure</em>
          </a>
        </div>
      )}
    </div>
  </section>
);

const EachAmenitie = ({ amenitiesProject, icono }) => {
  return (
    <div className="small-6 medium-4 cell item">
      <div className="icon">
        <img src={amenitiesProject.image_path} />
      </div>
      <div className="name">
        <h2>{amenitiesProject.nombre}</h2>
      </div>
    </div>
  );
};

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};
export default withRouter(Proyecto);
